import { REQUEST_STATUS } from "gsi-ui-components";
import { CASE_INFO } from "../helpers/types";

const initialState = { status: null, data: {} };

export default (state = initialState, action) => {
  switch (action.type) {
    case CASE_INFO.LOADING:
      return { ...initialState, status: REQUEST_STATUS.LOADING };
    case CASE_INFO.SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        data: action.payload
      };
    case CASE_INFO.ERROR:
      return { ...initialState, status: REQUEST_STATUS.ERROR };
    case CASE_INFO.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
