import { combineReducers } from "redux";
import { SIGN_OUT } from "../helpers/types";
import sidebar from "./sidebar";
import user from "./user";
import userList from "./userslist";
import ticketList from "./ticketlist";
import caseTask from "./caseTask";
import ticketInfo from "./ticketInfo";
import jenkins from "./jenkins";
import reviewers from "./reviewers";
import cases from "./cases";
import caseList from "./caselist";
import cloneCase from "./cloneCase";
import plannings from "./plannings";
import ticketTasks from "./ticketTasks";
import tasks from "./tasks";
import requestSupportCaseList from "./requestSupportCaseList";

const appReducer = combineReducers({
  sidebar: sidebar,
  user: user,
  userslist: userList,
  ticketlist: ticketList,
  caseTask: caseTask,
  ticketInfo: ticketInfo,
  jenkins: jenkins,
  reviewers: reviewers,
  cases: cases,
  caselist: caseList,
  cloneCase: cloneCase,
  plannings: plannings,
  ticketTasks: ticketTasks,
  tasks: tasks,
  requestSupportCaseList: requestSupportCaseList
});

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
