import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Table, Icon, Pagination, Tooltip } from "antd";
import { isEmpty } from "lodash";
import {
  dateToStringFormat,
  CASE_TICKET_ATTR,
  PAGINATION_SIZE,
  REQUEST_STATUS,
  SUPPORT_STATUS,
  sortFields
} from "gsi-ui-components";
import {
  getTicketList,
  setTicketDetailInfo,
  clearCaseTaskInfo
} from "../../actions/tickets";
import {
  filterTickets,
  getNextRequestQuery,
  getTotalTickets,
  parseInputData
} from "../../helpers/ticketsHelper";
import { clearBuildStatus } from "../../actions/jenkins";
import { clearCaseInfo, clearPlanningInfo } from "../../actions/cases";
import RequestDetails from "./requestDetails/RequestDetails";
import {
  iconFiltered,
  iconFilteredCalendar,
  inputSearch,
  rangePicker,
  sortDirectionsOptions
} from "../../helpers/casesHelper";
import "./TicketList.less";

const TicketList = ({
  getTicketList,
  ticketList,
  status,
  responseData,
  ticketDetailInfo,
  setTicketDetailInfo,
  clearCaseTaskInfo,
  clearBuildStatus,
  clearCaseInfo,
  clearPlanningInfo
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const requestDetailsInfo = useRef({});
  const activeFilters = useRef("");
  const totalItems = getTotalTickets(responseData);
  useEffect(() => {
    clearCaseTaskInfo();
    clearBuildStatus();
    clearCaseInfo();
    clearPlanningInfo();
    getTicketList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(ticketDetailInfo)) {
      requestDetailsInfo.current = ticketDetailInfo;
      setShowDetails(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageChange = (page, pageSize) => {
    const nextRequest = getNextRequestQuery(
      responseData,
      page,
      activeFilters.current,
      pageSize
    );

    getTicketList(nextRequest);
  };

  const reasonTitle = () => {
    return (
      <Tooltip placement="bottom" title={"Reason for request"}>
        Reason
      </Tooltip>
    );
  };

  const columns = [
    {
      title: "Ticket ID",
      dataIndex: CASE_TICKET_ATTR.ID,
      key: CASE_TICKET_ATTR.ID,
      width: "7rem",
      ellipsis: true,
      filterDropdown: filter => inputSearch(filter, "Ticket ID"),
      filterIcon: filter => iconFiltered(filter)
    },
    {
      title: "Case #",
      dataIndex: CASE_TICKET_ATTR.CASE_NUMBER,
      key: CASE_TICKET_ATTR.CASE_NUMBER,
      width: "7rem",
      ellipsis: true
    },
    {
      title: "Date",
      dataIndex: CASE_TICKET_ATTR.CREATED_DATE,
      key: CASE_TICKET_ATTR.CREATED_DATE,
      width: "10rem",
      filterDropdown: ({ setSelectedKeys, confirm }) =>
        rangePicker(setSelectedKeys, confirm),
      filterIcon: filter => iconFilteredCalendar(filter),
      render: text => {
        return dateToStringFormat(text);
      }
    },
    {
      title: "Status",
      dataIndex: CASE_TICKET_ATTR.STATE,
      key: CASE_TICKET_ATTR.STATE,
      width: "8rem",
      ellipsis: true,
      filters: [
        {
          text: SUPPORT_STATUS.TEXT.DRAFT,
          value: SUPPORT_STATUS.VALUE.DRAFT
        },
        {
          text: SUPPORT_STATUS.TEXT.INPROGRESS,
          value: SUPPORT_STATUS.VALUE.INPROGRESS
        },
        {
          text: SUPPORT_STATUS.TEXT.DONE,
          value: SUPPORT_STATUS.VALUE.DONE
        }
      ]
    },
    {
      title: reasonTitle,
      dataIndex: CASE_TICKET_ATTR.REASON,
      key: CASE_TICKET_ATTR.REASON,
      width: "7rem",
      ellipsis: true,
      sorter: (recordA, recordB) => sortFields(recordA.reason, recordB.reason)
    }
  ];

  return (
    <div className="ticket-table-container">
      <Table
        columns={columns}
        pagination={false}
        dataSource={parseInputData(ticketList, responseData)}
        sortDirections={sortDirectionsOptions}
        loading={{
          spinning: !status || status === REQUEST_STATUS.LOADING,
          indicator: <Icon type="loading" />
        }}
        onChange={(pagination, filters) =>
          filterTickets(filters, getTicketList, activeFilters)
        }
        scroll={{ x: true }}
        onRow={rowValue => {
          return {
            onClick: () => {
              requestDetailsInfo.current = {
                caseId: rowValue[CASE_TICKET_ATTR.CASE_ID],
                caseNumber: rowValue[CASE_TICKET_ATTR.CASE_NUMBER],
                ticketId: rowValue[CASE_TICKET_ATTR.ID],
                status: rowValue[CASE_TICKET_ATTR.STATE],
                reason: rowValue[CASE_TICKET_ATTR.REASON],
                date: rowValue[CASE_TICKET_ATTR.CREATED_DATE],
                userId: rowValue[CASE_TICKET_ATTR.USER_ID],
                issueId: rowValue[CASE_TICKET_ATTR.ISSUE_ID]
              };
              setShowDetails(true);
              setTicketDetailInfo(requestDetailsInfo.current);
            }
          };
        }}
      />

      {!isEmpty(ticketList) && (
        <Pagination
          onChange={pageChange}
          onShowSizeChange={pageChange}
          total={totalItems}
          defaultPageSize={PAGINATION_SIZE}
          showSizeChanger={true}
          pageSizeOptions={["10", "20", "50"]}
        />
      )}
      <RequestDetails
        {...requestDetailsInfo.current}
        visible={showDetails}
        setShowDetails={setShowDetails}
      />
    </div>
  );
};

TicketList.propTypes = {
  getTicketList: PropTypes.func,
  ticketList: PropTypes.array,
  status: PropTypes.string,
  responseData: PropTypes.object,
  ticketDetailInfo: PropTypes.object,
  setTicketDetailInfo: PropTypes.func,
  clearCaseTaskInfo: PropTypes.func,
  clearBuildStatus: PropTypes.func,
  clearPlanningInfo: PropTypes.func,
  clearCaseInfo: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = state => {
  return {
    ticketList: state.ticketlist.ticketList,
    status: state.ticketlist.status,
    responseData: state.ticketlist.responseData,
    ticketDetailInfo: state.ticketInfo
  };
};

export default connect(mapStateToProps, {
  getTicketList,
  setTicketDetailInfo,
  clearCaseTaskInfo,
  clearBuildStatus,
  clearPlanningInfo,
  clearCaseInfo
})(TicketList);
