import React, { useEffect } from "react";
import { Icon, Layout, Menu } from "antd";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { KEYCLOAK_USER_ROLES, BO_LOCATION_PATH } from "gsi-ui-components";
import { ReactComponent as GsiLogo } from "../../resources/layout/gsi-isotype-white.svg";
import { changeLocation } from "../../actions/sidebar";
import { signOut } from "../../actions/auth";
import "./SiderMenu.less";

const { Sider } = Layout;
const { SubMenu } = Menu;
const locationMenuItems = {
  [BO_LOCATION_PATH.ROOT]: "home",
  [BO_LOCATION_PATH.ADD_USER]: "user-add",
  [BO_LOCATION_PATH.REQUEST_SUPPORT]: "requestSupport",
  [BO_LOCATION_PATH.REQUEST_SUPPORT_CASES]: "requestSupportCases",
  [BO_LOCATION_PATH.CASE_TASK]: "requestSupport",
  [BO_LOCATION_PATH.SURGERY_CASES]: "requestSupport",
  [BO_LOCATION_PATH.CASE_LIST]: "userLoggedCaseList",
  [BO_LOCATION_PATH.FINISH_CASE_TASK]: "requestSupport",
  [BO_LOCATION_PATH.ANALYTICS]: "analytics"
};

const SiderMenu = ({ changeLocation, locationHash, signOut, userRoles }) => {
  const location = useLocation();
  const hasBackofficeRole = userRoles.includes(KEYCLOAK_USER_ROLES.BACKOFFICE);
  const hasSupportRole = userRoles.includes(
    KEYCLOAK_USER_ROLES.SUPPORT_ENGINEER
  );
  const hasSalesRepRole = userRoles.includes(KEYCLOAK_USER_ROLES.SALES_REP);

  if (hasBackofficeRole) {
    locationMenuItems[BO_LOCATION_PATH.USERS] = "all-users";
  } else if (hasSalesRepRole) {
    locationMenuItems[BO_LOCATION_PATH.USERS] = "users";
  }

  useEffect(() => {
    changeLocation(location.pathname);
  }, [changeLocation, location]);

  return (
    <Sider collapsedWidth={"2.5rem"} collapsed={true}>
      <Menu
        className="navigation-menu"
        selectedKeys={[locationMenuItems[locationHash]]}
        mode="inline"
        collapsed="true"
        forceSubMenuRender={true}
      >
        <div className="layout-logo-container">
          <GsiLogo className="layout-logo" />
        </div>
        <Menu.Item key="home" className="layout-selected">
          <Link to={BO_LOCATION_PATH.ROOT} />
          <Icon className="layout-icon" type="home" />
          <span>Home</span>
        </Menu.Item>
        {hasBackofficeRole && (
          <Menu.Item key="all-users" className="layout-selected">
            <Link to={BO_LOCATION_PATH.USERS} />
            <Icon className="layout-icon" type="user" />
            <span>All Users</span>
          </Menu.Item>
        )}
        {hasBackofficeRole && (
          <Menu.Item key="user-add" className="layout-selected">
            <Link to={BO_LOCATION_PATH.ADD_USER} />
            <Icon className="layout-icon" type="user-add" />
            <span>Create User</span>
          </Menu.Item>
        )}
        {hasSupportRole && (
          <Menu.Item key="requestSupport" className="layout-selected">
            <Link to={BO_LOCATION_PATH.REQUEST_SUPPORT} />
            <Icon className="layout-icon" type="unordered-list" />
            <span>List Support Tickets</span>
          </Menu.Item>
        )}
        {hasSupportRole && (
          <Menu.Item key="requestSupportCases" className="layout-selected">
            <Link to={BO_LOCATION_PATH.REQUEST_SUPPORT_CASES} />
            <Icon className="layout-icon" type="folder-open" />
            <span>Support Request Cases</span>
          </Menu.Item>
        )}
        {hasSalesRepRole && (
          <Menu.Item key="userLoggedCaseList" className="layout-selected">
            <Link to={BO_LOCATION_PATH.CASE_LIST} />
            <Icon className="layout-icon" type="folder-open" />
            <span>Cases Library</span>
          </Menu.Item>
        )}
        {hasBackofficeRole && (
          <Menu.Item key="analytics" className="layout-selected">
            <Link to={BO_LOCATION_PATH.ANALYTICS} />
            <Icon className="layout-icon" type="bar-chart" />
            <span>Analytics</span>
          </Menu.Item>
        )}
        <div className="menu-separator"></div>
        <SubMenu
          key="logout"
          className="layout-selected"
          title={
            <div className="layout-user-option">
              <Icon className="layout-user-icon" type="user" />
            </div>
          }
        >
          <Menu.Item
            key="logout"
            className="logout-border-top"
            onClick={() => {
              signOut(createBrowserHistory());
            }}
          >
            <Icon className="layout-user-icon" type="logout" />
            <span>Logout</span>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

SiderMenu.propTypes = {
  changeLocation: PropTypes.func,
  signOut: PropTypes.func,
  locationHash: PropTypes.string,
  userRoles: PropTypes.array
};

const mapStateToProps = state => {
  return {
    locationHash: state.sidebar.currentLocation,
    userRoles: state.user.userRoles
  };
};

export default React.memo(
  connect(mapStateToProps, { changeLocation, signOut })(SiderMenu)
);
