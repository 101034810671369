import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import { parseDateFormat, DATE_FORMAT } from "gsi-ui-components";
import "./InputDate.less";

const { RangePicker } = DatePicker;
const setDateFilter = (event, setSelectedKeys, confirm) => {
  const initialDateParsed = event[0] && event[0].toISOString().slice(0, 10);
  const endDateParsed = event[1] && event[1].toISOString().slice(0, 10);
  initialDateParsed && endDateParsed
    ? setSelectedKeys([
        parseDateFormat(initialDateParsed) +
          "-" +
          parseDateFormat(endDateParsed)
      ])
    : setSelectedKeys(null);
  confirm();
};

const InputDate = ({ setSelectedKeys, confirm }) => {
  return (
    <div style={{ padding: 8 }}>
      <RangePicker
        format={DATE_FORMAT.bigEndian}
        placeholder={["Start Date", "End Date"]}
        onPressEnter={() => confirm()}
        onChange={e => {
          setDateFilter(e, setSelectedKeys, confirm);
        }}
      />
    </div>
  );
};

InputDate.propTypes = {
  setSelectedKeys: PropTypes.func,
  confirm: PropTypes.func
};

export default InputDate;
