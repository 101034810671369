import React from "react";
import { connect } from "react-redux";
import { Col, Layout, Row } from "antd";
import { InfoCard } from "gsi-ui-components";
import BoHeader from "../layout/BoHeader";
import "./AnalyticsContainer.less";

const { Content } = Layout;

const AnalyticsContainer = () => {
  return (
    <Layout>
      <BoHeader title="Analytics" />
      <Content>
        <div className="analytics-container">
          <Row gutter={16} type="flex" justify="center">
            <Col span={12}>
              <InfoCard title="Cases">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://datastudio.google.com/u/0/reporting/f0b6bb48-7ff9-46c1-a667-13f22215911d/page/sw5FC"
                    >
                      SI Preview User Cases [prod]
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://datastudio.google.com/u/0/reporting/80df247f-d19c-45f1-a50e-2723665b4630/page/m6HFC"
                    >
                      SI Preview User Cases [stage]
                    </a>
                  </li>
                </ul>
              </InfoCard>
            </Col>
            <Col span={12}>
              <InfoCard title="Sessions">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://datastudio.google.com/u/0/reporting/809269db-6b43-4b85-b335-c84f9ed3d3c8/page/K9JWC"
                    >
                      DHR (Design History Record)[prod]
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://datastudio.google.com/u/0/reporting/54265162-780b-4620-b189-ea253d7bec77/page/qiOjB"
                    >
                      DHR (Design History Record)[stage]
                    </a>
                  </li>
                </ul>
              </InfoCard>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

AnalyticsContainer.propTypes = {};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, null)(AnalyticsContainer);
