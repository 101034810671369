import Cookies from "js-cookie";
import jwt from "jwt-decode";
import axios from "axios";
import {
  FORM_ENCODED_CONTENT_TYPE,
  BO_AUTH_COOKIE_NAME,
  INACTIVITY_REFRESH
} from "gsi-ui-components";

const getCookie = cookieName => {
  const cookie = Cookies.get(cookieName);
  return cookie ? JSON.parse(cookie) : null;
};

const getCookieField = (cookieName, field) => {
  const cookieContent = getCookie(cookieName);
  return cookieContent[field];
};

const deleteCookie = cookieName => {
  Cookies.remove(cookieName);
};

const getToken = (tokenName = "access_token") => {
  let token;
  try {
    token = getCookieField(BO_AUTH_COOKIE_NAME, tokenName);
  } catch {
    token = null;
  }

  return token;
};

const isTokenExpired = () => {
  const accessToken = getToken();
  const secondsToExpireToken = getSecondsToExpireToken(accessToken);

  return secondsToExpireToken <= 1;
};

const isRefreshTokenExpired = () => {
  let tokenExpired;
  try {
    const refreshToken = getToken("refresh_token");
    const secondsToExpireToken = getSecondsToExpireToken(refreshToken);
    tokenExpired = secondsToExpireToken <= 1;
  } catch {
    tokenExpired = true;
  }

  return tokenExpired;
};

const getRefreshTokenExpiration = () => {
  const refreshToken = getToken("refresh_token");
  const secondsToExpireToken = getSecondsToExpireToken(refreshToken);

  return (
    secondsToExpireToken *
    INACTIVITY_REFRESH.REFRESH_TOKEN_FORCE_PERCENTAGE *
    1000
  );
};

const getSecondsToExpireToken = token => {
  const tokenExpiresInEpoch = jwt(token).exp;
  const tokenExpiresIn = new Date(0).setUTCSeconds(tokenExpiresInEpoch);
  const localEpoch = Date.now();

  return Math.floor((tokenExpiresIn - localEpoch) / 1000);
};

const updateToken = async url => {
  await axios.get(url, {
    withCredentials: true,
    headers: {
      "Content-Type": FORM_ENCODED_CONTENT_TYPE
    }
  });
};

const getName = () => {
  const accessToken = getToken();

  return jwt(accessToken).name;
};

const getUsername = () => {
  const accessToken = getToken();

  return jwt(accessToken).preferred_username;
};

const getUserId = () => {
  const accessToken = getToken();

  return jwt(accessToken).sub;
};

const getUserRoles = () => {
  const accessToken = getToken();

  return jwt(accessToken).realm_access.roles;
};

const closeSession = async url => {
  await axios.get(url, { withCredentials: true });
};

const userService = {
  getCookie,
  deleteCookie,
  getName,
  getUsername,
  getUserId,
  getUserRoles,
  getToken,
  isTokenExpired,
  closeSession,
  updateToken,
  getRefreshTokenExpiration,
  isRefreshTokenExpired
};

export default userService;
