import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Layout, Row } from "antd";
import UserForm from "./UserForm";
import BoHeader from "../layout/BoHeader";

const { Content } = Layout;

const UserContainer = ({ isEditing }) => {
  return (
    <Layout>
      <BoHeader title={isEditing ? "Edit user" : "Create new user"} />
      <Content>
        <Row gutter={8}>
          <Col span={24}>
            <div className="container user">
              <UserForm />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

UserContainer.propTypes = {
  isEditing: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    isEditing: state.user.isEditing
  };
};

export default connect(mapStateToProps, null)(UserContainer);
