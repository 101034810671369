import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Icon, Tag, Tooltip } from "antd";
import GenericTable from "../common/GenericTable";
import {
  SURGERY_CASE_ATTR,
  REQUEST_STATUS,
  newCaseState,
  statusFilters,
  sortFields,
  BaseButton,
  parseDateToFilterFormat,
  SORT_DIRECTIONS
} from "gsi-ui-components";
import {
  filterCases,
  parseInputData,
  getCaseSearchPath,
  sortDirectionsOptions,
  inputSearch,
  iconFiltered,
  rangePicker,
  iconFilteredCalendar
} from "../../helpers/casesHelper";
import { getSupportCasesList } from "../../actions/cases";
import { buttonConsts } from "../../helpers/consts";
import "./RequestSupportCases.less";

const RequestSupportCases = ({
  getSupportCasesList,
  caseListData,
  responseData,
  status,
  history
}) => {
  const activeFilters = useRef("");
  const [filtersCleared, setFiltersCleared] = useState(true);
  const [activeFiltersObject, setActiveFiltersObject] = useState([]);

  const clearFiltersButton = () => (
    <BaseButton
      config={{
        type: buttonConsts.TYPE.LINK,
        size: buttonConsts.SIZE.SMALL,
        disabled: filtersCleared
      }}
      onClick={() => {
        setFiltersCleared(true);
        setActiveFiltersObject([]);
        filterCases([], getSupportCasesList, activeFilters, setFiltersCleared);
      }}
      text="Clear filters"
    />
  );

  useEffect(() => {
    getSupportCasesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionButtons = (text, value) => {
    return (
      <Tooltip title="View case">
        <Icon
          className="view-case-icon"
          type="eye"
          onClick={() => {
            history.push(getCaseSearchPath(value.id));
          }}
        />
      </Tooltip>
    );
  };

  const columns = [
    {
      title: "Case #",
      dataIndex: SURGERY_CASE_ATTR.CASE_NUMBER,
      key: SURGERY_CASE_ATTR.CASE_NUMBER,
      ellipsis: true,
      filterDropdown: filter => inputSearch(filter, "Case"),
      filterIcon: filter => iconFiltered(filter),
      sorter: (recordA, recordB) =>
        sortFields(recordA.caseNumber, recordB.caseNumber),
      filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.CASE_NUMBER] || null
    },
    {
      title: "Surgeon",
      dataIndex: SURGERY_CASE_ATTR.SURGEON_ID,
      key: SURGERY_CASE_ATTR.SURGEON_ID,
      ellipsis: true,
      sorter: (recordA, recordB) => sortFields(recordA.userId, recordB.userId)
    },
    {
      title: "Surgery Date",
      dataIndex: SURGERY_CASE_ATTR.SURGERY_DATE,
      key: SURGERY_CASE_ATTR.SURGERY_DATE,
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, confirm }) =>
        rangePicker(setSelectedKeys, confirm),
      filterIcon: filter => iconFilteredCalendar(filter),
      sorter: (recordA, recordB) => sortFields(recordA.date, recordB.date),
      filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.SURGERY_DATE] || null
    },
    {
      title: "Status",
      dataIndex: SURGERY_CASE_ATTR.STATE,
      key: SURGERY_CASE_ATTR.STATE,
      ellipsis: true,
      filters: statusFilters,
      render: (text, record) => {
        const tagText = record.isDeclined
          ? newCaseState.DECLINED
          : text.toUpperCase();
        return {
          props: {
            className: `status-${tagText.toLowerCase()}`
          },
          children: <Tag>{tagText}</Tag>
        };
      },
      sorter: (recordA, recordB) => sortFields(recordA.state, recordB.state),
      filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.STATE] || null
    },
    {
      title: "Comment",
      dataIndex: SURGERY_CASE_ATTR.CLASSIFICATION,
      key: SURGERY_CASE_ATTR.CLASSIFICATION,
      ellipsis: true,
      filterDropdown: filter => inputSearch(filter, "Comment"),
      filterIcon: filter => iconFiltered(filter),
      sorter: (recordA, recordB) =>
        sortFields(recordA.classification, recordB.classification),
      filteredValue:
        activeFiltersObject[SURGERY_CASE_ATTR.CLASSIFICATION] || null
    },
    {
      title: "Last modified",
      dataIndex: SURGERY_CASE_ATTR.LAST_MODIFIED,
      key: SURGERY_CASE_ATTR.LAST_MODIFIED,
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, confirm }) =>
        rangePicker(setSelectedKeys, confirm),
      filterIcon: filter => iconFilteredCalendar(filter),
      defaultSortOrder: SORT_DIRECTIONS.DESCEND,
      sorter: (recordA, recordB) => {
        if (isNaN(new Date(recordA.lastModified))) return 1;
        if (isNaN(new Date(recordB.lastModified))) return -1;
        return new Date(recordA.lastModified) - new Date(recordB.lastModified);
      },
      filteredValue:
        activeFiltersObject[SURGERY_CASE_ATTR.LAST_MODIFIED] || null,
      render: (_text, record) => {
        let lastModified = record.lastModified;

        if (lastModified !== "-") {
          lastModified = new Date(record.lastModified);
          lastModified = parseDateToFilterFormat(lastModified);
          const dateIsToday =
            lastModified === parseDateToFilterFormat(new Date());
          if (dateIsToday) {
            lastModified = "Today";
          }
          if (record.isDeclined) {
            lastModified = "";
          }
        }
        return {
          children: <div>{lastModified}</div>
        };
      }
    },
    {
      title: clearFiltersButton,
      dataIndex: "actions",
      key: "actions",
      render: actionButtons
    }
  ];
  return (
    <GenericTable
      columns={columns}
      pagination={{
        defaultPageSize: 20,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50"]
      }}
      className="request-support-cases"
      title="Cases"
      dataSource={parseInputData(caseListData, responseData)}
      sortDirections={sortDirectionsOptions}
      loading={{
        spinning: !status || status === REQUEST_STATUS.LOADING,
        indicator: <Icon type="loading" />
      }}
      onChange={(pagination, filters) => {
        setActiveFiltersObject(filters);
        filterCases(
          filters,
          getSupportCasesList,
          activeFilters,
          setFiltersCleared
        );
      }}
    />
  );
};
RequestSupportCases.propTypes = {
  history: PropTypes.object,
  getSupportCasesList: PropTypes.func,
  caseListData: PropTypes.array,
  status: PropTypes.string,
  responseData: PropTypes.object
};

const mapStateToProps = state => {
  return {
    caseListData: state.requestSupportCaseList.caseList,
    status: state.requestSupportCaseList.status,
    responseData: state.requestSupportCaseList.responseData
  };
};

export default connect(mapStateToProps, {
  getSupportCasesList
})(RequestSupportCases);
