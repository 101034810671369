import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { capitalize, isEmpty } from "lodash";
import { Icon, Layout, Spin } from "antd";
import {
  InfoCard,
  InputCell,
  WarningDisclaimer,
  SurgeryPlanningInfo,
  BaseButton,
  BaseInfoDetail,
  calculateAge,
  formatDate,
  parseDateFormat,
  checkValue,
  inputType,
  REQUEST_STATUS,
  newCaseState
} from "gsi-ui-components";
import BoHeader from "../layout/BoHeader";
import {
  getCaseInfo,
  getPlanningInfo,
  sendSurgeryNote
} from "../../actions/cases";
import { generateReport } from "../../helpers/reportGeneration";
import { getAllPlanningDates } from "../../helpers/ticketsHelper";
import { BO_PAGE_TITLE, buttonConsts } from "../../helpers/consts";
import "./CaseDetail.less";

const { Content } = Layout;

const CaseDetail = ({
  caseInfo,
  planningInfo,
  getCaseInfo,
  getPlanningInfo,
  sendSurgeryNote
}) => {
  const params = useParams();
  const location = useLocation();

  const SECTIONS = {
    PATIENT: "patient",
    SURGERY: "surgery",
    NOTES: "notes",
    PLANNING: "planning"
  };
  const lastModifiedPlanningDates = getAllPlanningDates(planningInfo.data);

  const [patientValues, setPatientValues] = useState({});
  const [surgeryValues, setSurgeryValues] = useState({});
  const [activeTab, setActiveTab] = useState("0");
  const [noteValue, setNoteValue] = useState("");
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState(null);

  useEffect(() => {
    document.title = BO_PAGE_TITLE;
    params.id && getCaseInfo(params.id);
    const partialRoute = location?.state?.breadcrumbRoutes;

    if (partialRoute) {
      const updatedRoute = [
        ...partialRoute,
        {
          path: "",
          breadcrumbName: ""
        }
      ];
      setBreadcrumbRoutes(updatedRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (caseInfo.status === REQUEST_STATUS.SUCCESS) {
      setPatientValues({
        Name: caseInfo.data.patient.fullName,
        MRN: caseInfo.data.medicalRecordNumber,
        Gender: capitalize(caseInfo.data.patient.gender),
        "Date of birth": parseDateFormat(caseInfo.data.patient.birthDate),
        Age:
          caseInfo.data.patient.birthDate &&
          calculateAge(formatDate(caseInfo.data.patient.birthDate)).toString()
      });

      setSurgeryValues({
        "Operation Side": capitalize(caseInfo.data.operationSide),
        Comment: caseInfo.data.classification
      });

      getPlanningInfo(
        caseInfo.data.surgeryPlannings?.references,
        caseInfo.data.planner3DImages?.references
      );

      setNoteValue(caseInfo.data.surgeryNote);
      document.title = `GSI SI Case ${caseInfo.data.caseNumber}`;

      if (
        !isEmpty(breadcrumbRoutes) &&
        breadcrumbRoutes.at(-1)["path"] !== params.id
      ) {
        if (breadcrumbRoutes.at(-1)["path"] === "") {
          breadcrumbRoutes.pop();
        }
        const updatedRoute = [
          ...breadcrumbRoutes,
          {
            path: params.id,
            breadcrumbName: caseInfo.data.caseNumber
          }
        ];

        setBreadcrumbRoutes(updatedRoute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseInfo]);

  const getCardContent = (section, values) => {
    if (
      caseInfo.status === REQUEST_STATUS.SUCCESS &&
      (section !== SECTIONS.PLANNING ||
        planningInfo.status === REQUEST_STATUS.SUCCESS)
    ) {
      switch (section) {
        case SECTIONS.NOTES:
          return (
            <>
              <InputCell
                type={inputType.TEXTAREA}
                config={{
                  minRows: 3,
                  maxRows: 6,
                  maxlength: 255,
                  placeholder: "Add a description here",
                  disabled: false,
                  value: noteValue
                }}
                onChange={e => {
                  setNoteValue(e.target.value);
                }}
              />
              <BaseButton
                config={{
                  className: "submit-button",
                  type: buttonConsts.TYPE.PRIMARY,
                  size: buttonConsts.SIZE.DEFAULT
                }}
                text="Submit"
                onClick={() => sendSurgeryNote(params.id, noteValue)}
              />
            </>
          );
        case SECTIONS.PLANNING:
          return (
            <SurgeryPlanningInfo
              surgeryPlanning={planningInfo.data}
              surgeryMeasurements={caseInfo.data.surgeryMeasurements}
              operationSideValue={caseInfo.data.operationSide}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          );
        default:
          return (
            <div className="card-body-container">
              {Object.keys(values).map(item => (
                <BaseInfoDetail
                  key={item}
                  title={item}
                  data={checkValue(values[item])}
                />
              ))}
            </div>
          );
      }
    } else if (!caseInfo.status || caseInfo.status === REQUEST_STATUS.ERROR) {
      return (
        <Spin
          className={`spinner ${section}-empty`}
          indicator={<Icon type="exclamation-circle" />}
        />
      );
    } else {
      return (
        <Spin
          className={`spinner ${section}-empty`}
          indicator={<Icon type="loading" />}
        />
      );
    }
  };

  return (
    <Layout>
      <BoHeader title="Case details" breadcrumbRoutes={breadcrumbRoutes} />
      <Content>
        <div className="casedetail-layout">
          <div className="casedetail-title">
            Case #{checkValue(caseInfo.data.caseNumber)}
            <BaseButton
              config={{
                type: "default",
                shape: buttonConsts.SHAPE.CIRCLE,
                size: buttonConsts.SIZE.SMALL,
                disabled:
                  caseInfo.status !== REQUEST_STATUS.SUCCESS ||
                  planningInfo.status !== REQUEST_STATUS.SUCCESS ||
                  caseInfo.data.state !== newCaseState.PLANNED,
                tooltip: "View report",
                icon: "file-pdf"
              }}
              onClick={() =>
                generateReport(
                  caseInfo,
                  planningInfo,
                  patientValues,
                  lastModifiedPlanningDates
                )
              }
            />
          </div>
          <div className="casedetail-subtitle">Read only</div>
          <div className="container container-columns">
            <div>
              <InfoCard
                title="Patient's information"
                titleLinks={[
                  {
                    isText: true,
                    linkText: <WarningDisclaimer />
                  }
                ]}
              >
                {getCardContent(SECTIONS.PATIENT, patientValues)}
              </InfoCard>
              <InfoCard
                title="Surgery data"
                titleLinks={[
                  {
                    isText: true,
                    linkText: <WarningDisclaimer />
                  }
                ]}
              >
                {getCardContent(SECTIONS.SURGERY, surgeryValues)}
              </InfoCard>
            </div>
            <div>
              <InfoCard
                title="Notes"
                titleLinks={[
                  {
                    isText: true,
                    linkText: <WarningDisclaimer />
                  }
                ]}
                isLast
              >
                {getCardContent(SECTIONS.NOTES)}
              </InfoCard>
            </div>
            <div className="planning-infocard">
              <InfoCard title="3D surgery planning">
                {getCardContent(SECTIONS.PLANNING)}
              </InfoCard>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

CaseDetail.propTypes = {
  caseInfo: PropTypes.object,
  planningInfo: PropTypes.object,
  getCaseInfo: PropTypes.func,
  getPlanningInfo: PropTypes.func,
  sendSurgeryNote: PropTypes.func
};

const mapStateToProps = state => {
  return {
    caseInfo: state.cases,
    planningInfo: state.plannings
  };
};

export default connect(mapStateToProps, {
  getCaseInfo,
  getPlanningInfo,
  sendSurgeryNote
})(CaseDetail);
