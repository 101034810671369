const buttonType = {
  PRIMARY: "primary",
  DEFAULT: "default",
  LINK: "link"
};

const buttonSize = {
  LARGE: "large",
  DEFAULT: "default",
  SMALL: "small"
};

const buttonShape = {
  CIRCLE: "circle",
  ROUND: "round"
};

export const buttonConsts = {
  TYPE: buttonType,
  SIZE: buttonSize,
  SHAPE: buttonShape
};

export const BO_PAGE_TITLE = "GSI SI Backoffice";
