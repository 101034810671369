import React from "react";
import { Layout, Table } from "antd";
import PropTypes from "prop-types";
import BoHeader from "../layout/BoHeader";

const { Content } = Layout;

const GenericTable = ({
  title,
  columns,
  dataSource,
  pagination = false,
  className = "",
  sortDirections = ["ascend", "descend"],
  loading = false,
  onChange = () => undefined,
  onRow = () => undefined,
  breadcrumbRoutes = null
}) => {
  return (
    <Layout>
      <BoHeader title={title} breadcrumbRoutes={breadcrumbRoutes} />
      <Content>
        <div className="table-container">
          <Table
            tableLayout="auto"
            columns={columns}
            scroll={{ x: true }}
            dataSource={dataSource}
            pagination={pagination}
            className={className}
            sortDirections={sortDirections}
            loading={loading}
            onChange={onChange}
            onRow={onRow}
          />
        </div>
      </Content>
    </Layout>
  );
};

GenericTable.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  sortDirections: PropTypes.array,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onChange: PropTypes.func,
  onRow: PropTypes.func,
  breadcrumbRoutes: PropTypes.array
};

export default GenericTable;
