import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Icon, Tooltip, message } from "antd";
import { capitalize } from "lodash";
import {
  SURGERY_CASE_ATTR,
  REQUEST_STATUS,
  newCaseState,
  parseDateFormat,
  formatDate,
  calculateAge,
  BaseModal,
  requestStatus,
  BaseButton
} from "gsi-ui-components";
import {
  filterCases,
  getListColumn,
  parseInputData,
  showCloneStatusMessage,
  sortDirectionsOptions
} from "../../helpers/casesHelper";
import {
  getCaseInfo,
  getCaseList,
  getPlanningInfo,
  cloneCase,
  clearCloneCaseData
} from "../../actions/cases";
import GenericTable from "../common/GenericTable";
import { buttonConsts } from "../../helpers/consts";
import { generateReport } from "../../helpers/reportGeneration";
import { getAllPlanningDates } from "../../helpers/ticketsHelper";
import "./CaseList.less";

let generatingReportMessage;

const CaseList = ({
  getCaseInfo,
  getCaseList,
  cloneCase,
  cloneCaseStatus,
  clearCloneCaseData,
  getPlanningInfo,
  caseListData,
  caseInfo,
  planningInfo,
  responseData,
  status
}) => {
  const casesInfo = useRef({});
  const activeFilters = useRef("");
  const cloneInfo = useRef({});
  const viewReportRequested = useRef(false);
  const [isCloneCaseModalVisible, setIsCloneCaseModalVisible] = useState(false);
  const [filtersCleared, setFiltersCleared] = useState(true);
  const [activeFiltersObject, setActiveFiltersObject] = useState([]);

  useEffect(() => {
    getCaseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      viewReportRequested.current &&
      caseInfo.status === REQUEST_STATUS.SUCCESS
    ) {
      getPlanningInfo(
        caseInfo.data.surgeryPlannings?.references,
        caseInfo.data.planner3DImages?.references
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseInfo]);

  useEffect(() => {
    if (
      viewReportRequested.current &&
      planningInfo.status === REQUEST_STATUS.SUCCESS
    ) {
      const lastModifiedPlanningDates = getAllPlanningDates(planningInfo.data);
      generateReport(
        caseInfo,
        planningInfo,
        {
          Name: caseInfo.data.patient.fullName,
          MRN: caseInfo.data.medicalRecordNumber,
          Gender: capitalize(caseInfo.data.patient.gender),
          "Date of birth": parseDateFormat(caseInfo.data.patient.birthDate),
          Age:
            caseInfo.data.patient.birthDate &&
            calculateAge(formatDate(caseInfo.data.patient.birthDate)).toString()
        },
        lastModifiedPlanningDates
      );
      viewReportRequested.current = false;
      generatingReportMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningInfo]);

  useEffect(() => {
    if (cloneInfo.current) {
      showCloneStatusMessage(
        cloneInfo.current.patientName,
        cloneCaseStatus,
        message,
        clearCloneCaseData
      );
    }
    if (cloneCaseStatus === requestStatus.SUCCESS) {
      getCaseList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloneCaseStatus, clearCloneCaseData]);

  const clearFiltersButton = () => (
    <BaseButton
      config={{
        type: buttonConsts.TYPE.LINK,
        size: buttonConsts.SIZE.SMALL,
        disabled: filtersCleared
      }}
      onClick={() => {
        setFiltersCleared(true);
        setActiveFiltersObject([]);
        filterCases([], getCaseList, activeFilters, setFiltersCleared);
      }}
      text="Clear filters"
    />
  );

  const actionButtons = (text, record) => {
    return (
      <div className="cases-list-icon-container">
        <Tooltip title="Clone case" placement="bottom">
          <Icon
            type="copy"
            onClick={() => {
              cloneInfo.current = {
                patientName: record.patientName,
                caseNumber: record.caseNumber
              };
              setIsCloneCaseModalVisible(true);
            }}
          />
        </Tooltip>
        <Tooltip title="View case" placement="bottom">
          <Icon type="eye" />
        </Tooltip>
        <Tooltip title="View report" placement="bottom">
          <Icon
            type="file-pdf"
            onClick={() => {
              if (record.state === newCaseState.PLANNED) {
                generatingReportMessage = message.loading(
                  "Generating PDF report"
                );
                viewReportRequested.current = true;
                getCaseInfo(record.id);
              }
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const columns = [
    getListColumn(SURGERY_CASE_ATTR.CASE_NUMBER, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.SURGEON_NAME, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.MODALITY, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.MODALITY, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.MRN, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.SURGERY_DATE, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.STATE, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.COMMENT, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.LAST_MODIFIED, activeFiltersObject),
    getListColumn(
      "actions",
      activeFiltersObject,
      clearFiltersButton,
      actionButtons
    )
  ];

  return (
    <React.Fragment>
      <GenericTable
        columns={columns}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"]
        }}
        title="Cases List"
        className="cases-list"
        dataSource={parseInputData(caseListData, responseData)}
        sortDirections={sortDirectionsOptions}
        loading={{
          spinning: !status || status === REQUEST_STATUS.LOADING,
          indicator: <Icon type="loading" />
        }}
        onChange={(pagination, filters) => {
          setActiveFiltersObject(filters);
          filterCases(filters, getCaseList, activeFilters, setFiltersCleared);
        }}
        onRow={rowValue => {
          return {
            onClick: () => {
              casesInfo.current = {
                patientName: rowValue[SURGERY_CASE_ATTR.PATIENT_NAME],
                modality: rowValue[SURGERY_CASE_ATTR.MODALITY],
                mrn: rowValue[SURGERY_CASE_ATTR.MRN],
                surgeryDate: rowValue[SURGERY_CASE_ATTR.SURGERY_DATE],
                state: rowValue[SURGERY_CASE_ATTR.STATE],
                comment: rowValue[SURGERY_CASE_ATTR.CLASSIFICATION],
                lastModified: rowValue[SURGERY_CASE_ATTR.LAST_MODIFIED]
              };
            }
          };
        }}
      />
      <BaseModal
        handleCancel={() => setIsCloneCaseModalVisible(false)}
        config={{
          visible: isCloneCaseModalVisible,
          maskClosable: false,
          title: "Clone case",
          footer: [
            {
              config: {
                type: buttonConsts.TYPE.LINK,
                size: buttonConsts.SIZE.DEFAULT
              },
              onClick: () => {
                setIsCloneCaseModalVisible(false);
              },
              text: "Cancel"
            },
            {
              config: {
                type: buttonConsts.TYPE.PRIMARY,
                size: buttonConsts.SIZE.DEFAULT
              },
              onClick: () => {
                cloneCase(cloneInfo.current.caseNumber);
                setIsCloneCaseModalVisible(false);
              },
              text: "Clone case"
            }
          ]
        }}
      >
        <div>You are about to clone this case to your library.</div>
      </BaseModal>
    </React.Fragment>
  );
};

CaseList.propTypes = {
  getCaseInfo: PropTypes.func,
  getCaseList: PropTypes.func,
  cloneCase: PropTypes.func,
  cloneCaseStatus: PropTypes.string,
  clearCloneCaseData: PropTypes.func,
  getPlanningInfo: PropTypes.func,
  caseListData: PropTypes.array,
  caseInfo: PropTypes.object,
  responseData: PropTypes.object,
  planningInfo: PropTypes.object,
  status: PropTypes.string
};

const mapStateToProps = state => {
  return {
    caseListData: state.caselist.caseList,
    caseInfo: state.cases,
    status: state.caselist.status,
    cloneCaseStatus: state.cloneCase.cloneCaseStatus,
    responseData: state.caselist.responseData,
    planningInfo: state.plannings
  };
};

export default connect(mapStateToProps, {
  getCaseList,
  cloneCase,
  clearCloneCaseData,
  getCaseInfo,
  getPlanningInfo
})(CaseList);
