import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  Select,
  Switch
} from "antd";
import PropTypes from "prop-types";
import {
  InfoCard,
  checkLoadingStatus,
  isCreateUserDisabled,
  showActionStatusMessage,
  FORM_ITEM_LAYOUT,
  RESET_PASSWORD_MESSAGE,
  USER_ACTION_RELATED_MESSAGE
} from "gsi-ui-components";
import { Prompt } from "react-router-dom";
import {
  clearPasswordResetStatus,
  clearUserActionStatus,
  createUser,
  resetPassword,
  resetUserInfo,
  updateUser
} from "../../actions/user";
import "./UserForm.less";
import { RESET_PASSWORD, USER_ACTION_RELATED } from "../../helpers/types";

const { Content } = Layout;

const UserForm = ({
  clearUserActionStatus,
  clearPasswordResetStatus,
  createUser,
  updateUser,
  createUserStatus,
  resetPasswordStatus,
  resetUserInfo,
  errorMessage,
  isEditing,
  selectedUser,
  resetPassword,
  form
}) => {
  const {
    getFieldDecorator,
    getFieldsError,
    isFieldTouched,
    resetFields,
    setFieldsValue,
    validateFields
  } = form;

  const { Option } = Select;

  useEffect(() => {
    showActionStatusMessage(
      createUserStatus,
      message,
      clearUserActionStatus,
      isEditing ? false : resetFields,
      errorMessage,
      USER_ACTION_RELATED,
      USER_ACTION_RELATED_MESSAGE
    );
  }, [
    clearUserActionStatus,
    createUserStatus,
    errorMessage,
    isEditing,
    resetFields
  ]);

  useEffect(() => {
    showActionStatusMessage(
      resetPasswordStatus,
      message,
      clearPasswordResetStatus,
      false,
      errorMessage,
      RESET_PASSWORD,
      RESET_PASSWORD_MESSAGE
    );
  }, [clearPasswordResetStatus, resetPasswordStatus, errorMessage]);

  useEffect(() => {
    isEditing && setFieldsValue({ ...selectedUser });
  }, [isEditing, selectedUser, setFieldsValue]);

  const checkNavigation = () => {
    resetUserInfo();
    resetFields();
    return true;
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        isEditing ? updateUser(values) : createUser(values);
      }
    });
  };

  return (
    <Layout>
      <Content>
        <div className="userform-container">
          <Row align="middle" justify="center" type={"flex"}>
            <Col span={12}>
              <InfoCard>
                <Form
                  className="new-user-form"
                  {...FORM_ITEM_LAYOUT}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <Form.Item label="First name">
                    {getFieldDecorator("firstName", {
                      rules: [
                        {
                          required: true,
                          message: "Please input a name"
                        },
                        {
                          whitespace: true,
                          message: "Please input a valid name"
                        }
                      ]
                    })(<Input autoFocus maxLength={50} />)}
                  </Form.Item>
                  <Form.Item label="Last name">
                    {getFieldDecorator("lastName", {
                      rules: [
                        {
                          required: true,
                          message: "Please input a last name"
                        },
                        {
                          whitespace: true,
                          message: "Please input a valid last name"
                        }
                      ]
                    })(<Input maxLength={50} />)}
                  </Form.Item>
                  <Form.Item label="Username">
                    {getFieldDecorator("username", {
                      rules: [
                        {
                          required: true,
                          message: "Please input a username"
                        },
                        {
                          pattern: /^[A-Za-z0-9]*$/,
                          message: "Only letters and numbers are allowed"
                        }
                      ]
                    })(<Input maxLength={50} disabled={isEditing} />)}
                  </Form.Item>
                  <Form.Item label="Role">
                    {getFieldDecorator("role", {
                      initialValue: "surgeon",
                      rules: [
                        {
                          required: true,
                          message: "Please select a role for the user"
                        }
                      ]
                    })(
                      <Select disabled={isEditing}>
                        <Option value="surgeon">Surgeon</Option>
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="Email">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          type: "email",
                          message: "The input is not a valid email"
                        },
                        {
                          required: true,
                          message: "Please input an email"
                        }
                      ]
                    })(<Input maxLength={100} />)}
                  </Form.Item>
                  {isEditing && (
                    <Form.Item label="Enabled">
                      {getFieldDecorator("enabled", {
                        valuePropName: "checked",
                        initialValue: selectedUser.enabled
                      })(<Switch />)}
                      <Button
                        type="link"
                        className="resetpassword-button"
                        onClick={resetPassword}
                        disabled={checkLoadingStatus([
                          resetPasswordStatus,
                          createUserStatus
                        ])}
                      >
                        Reset password
                      </Button>
                    </Form.Item>
                  )}
                  <Form.Item
                    wrapperCol={{
                      offset: 9,
                      span: 16
                    }}
                  >
                    <Button
                      type="primary"
                      className="createuser-button"
                      disabled={isCreateUserDisabled(
                        getFieldsError,
                        isFieldTouched,
                        createUserStatus,
                        resetPasswordStatus,
                        isEditing
                      )}
                      htmlType="submit"
                    >
                      {isEditing ? "Save changes" : "Create User"}
                    </Button>
                  </Form.Item>
                  <Prompt message={checkNavigation} />
                </Form>
              </InfoCard>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

UserForm.propTypes = {
  form: PropTypes.object,
  selectedUser: PropTypes.object,
  clearUserActionStatus: PropTypes.func,
  clearPasswordResetStatus: PropTypes.func,
  createUser: PropTypes.func,
  getFieldDecorator: PropTypes.func,
  getFieldsError: PropTypes.func,
  updateUser: PropTypes.func,
  resetPassword: PropTypes.func,
  resetUserInfo: PropTypes.func,
  isFieldTouched: PropTypes.bool,
  isEditing: PropTypes.bool,
  createUserStatus: PropTypes.string,
  resetPasswordStatus: PropTypes.string,
  errorMessage: PropTypes.string
};

const mapStateToProps = state => {
  return {
    createUserStatus: state.user.status,
    resetPasswordStatus: state.user.resetPasswordStatus,
    errorMessage: state.user.errorMessage,
    isEditing: state.user.isEditing,
    selectedUser: state.user.selectedUser
  };
};

export default connect(mapStateToProps, {
  clearUserActionStatus,
  clearPasswordResetStatus,
  createUser,
  resetPassword,
  updateUser,
  resetUserInfo
})(Form.create()(UserForm));
