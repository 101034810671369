import React from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import BreadcrumbContainer from "../common/BreadcrumbContainer";
import "./BoHeader.less";

const { Header } = Layout;

const BoHeader = ({ title, breadcrumbRoutes = null }) => {
  return (
    <Header>
      <div className="header-title">
        {breadcrumbRoutes ? BreadcrumbContainer(breadcrumbRoutes) : title}
      </div>
    </Header>
  );
};

BoHeader.propTypes = {
  title: PropTypes.string,
  breadcrumbRoutes: PropTypes.array
};

export default React.memo(BoHeader);
