import React from "react";
import ReactDOM from "react-dom";
import { init, setUser, setTag, configureScope } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  userService,
  BO_AUTH_COOKIE_NAME,
  backofficeSentryURL
} from "gsi-ui-components";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage/session";
import reducers from "./reducers";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

init({
  dsn: process.env.REACT_APP_SENTRY_URL || backofficeSentryURL,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

const cookie = userService.getCookie(BO_AUTH_COOKIE_NAME);

if (cookie) {
  setUser({
    id: userService.getUserId(),
    username: userService.getUsername(),
    email: userService.getUserEmail(),
    ip_address: "{{auto}}"
  });
  setTag("username", userService.getUsername());
  setTag("userID", userService.getUserId());
  configureScope(scope => {
    scope.setTag("session", userService.getSessionId());
  });
}

const persistConfig = {
  key: "gsi-backoffice",
  storage
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

/* If you want to start measuring performance in your app, pass a function
   to log results (for example: reportWebVitals(console.log))
   or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
reportWebVitals();
