import { isEmpty } from "lodash";
import React from "react";
import { Icon, Tag } from "antd";
import moment from "moment";
import {
  DATE_FORMAT,
  BO_LOCATION_PATH,
  getFormatDateFromStringDate,
  SHORT_DATE_FORMAT,
  todayFormat,
  getCasesEndpoint,
  ASCII_ENCODE,
  SURGERY_CASE_ATTR,
  newCaseState,
  caseFilterType,
  checkValue,
  requestStatus,
  sortFields,
  modalityFilters,
  statusFilters,
  SORT_DIRECTIONS
} from "gsi-ui-components";
import InputSearch from "../components/common/InputSearch";
import InputDate from "../components/common/InputDate";
import QualityTag from "../components/common/QualityTag";

export const filterCases = (
  filters,
  getCaseList,
  activeFilters,
  setFiltersCleared
) => {
  let filter;
  let requestParams = "";

  for (filter in filters) {
    let selectedFilter = filters[filter];
    if (!isEmpty(selectedFilter)) {
      let filterBy = filter;

      switch (filter) {
        case SURGERY_CASE_ATTR.STATE:
          ({ selectedFilter, filterBy } = getStateFilter(
            filterBy,
            selectedFilter
          ));
          break;
        case SURGERY_CASE_ATTR.SURGERY_DATE:
          ({ selectedFilter, filterBy } = getSurgeryDateFilter(selectedFilter));
          break;
        case SURGERY_CASE_ATTR.LAST_MODIFIED:
          ({ selectedFilter, filterBy } =
            getLastModifiedDateFilter(selectedFilter));
          break;
        case SURGERY_CASE_ATTR.MODALITY:
          ({ selectedFilter, filterBy } = getModalityFilter(
            filterBy,
            selectedFilter
          ));
          break;
        case SURGERY_CASE_ATTR.PATIENT_NAME:
        case SURGERY_CASE_ATTR.MRN:
        case SURGERY_CASE_ATTR.CLASSIFICATION:
        case SURGERY_CASE_ATTR.CASE_NUMBER:
          ({ selectedFilter, filterBy } = getTextFilter(
            selectedFilter,
            filter
          ));
          break;
        default:
          break;
      }
      requestParams = requestParams.concat(`&${filterBy}=${selectedFilter}`);
    }
  }

  setFiltersCleared(isEmpty(filters));

  activeFilters.current = requestParams;

  getCaseList(`${getCasesEndpoint()}${requestParams}`);
};

const getTextFilter = (selectedFilter, filter) => {
  return {
    selectedFilter: `${ASCII_ENCODE.PERCENT}${encodeURIComponent(
      selectedFilter
    )}${ASCII_ENCODE.PERCENT}`,
    filterBy: caseFilterType[filter]
  };
};

const getStateFilter = (_filterBy, selectedFilter) => {
  const filterByValue = `${SURGERY_CASE_ATTR.STATE}`;
  return {
    selectedFilter: selectedFilter.join(ASCII_ENCODE.PIPE),
    filterBy: filterByValue
  };
};

const getModalityFilter = (_filterBy, selectedFilter) => {
  const filterByValue = `${SURGERY_CASE_ATTR.MODALITY}`;
  return {
    selectedFilter: selectedFilter.join(ASCII_ENCODE.PIPE),
    filterBy: filterByValue
  };
};

export const getLastModifiedDateFilter = selectedFilter => {
  const filteredDates = selectedFilter[0].split("-");
  const firstDate = new Date(filteredDates[0]);
  firstDate.setUTCHours(0, 0, 0, 0);
  const firstDateIso = firstDate.toISOString();

  const secondDate = new Date(filteredDates[1]);
  secondDate.setUTCHours(23, 59, 59, 999);
  const secondDateIso = secondDate.toISOString();

  return {
    selectedFilter: `${firstDateIso}~${secondDateIso}`,
    filterBy: "lastModifiedPlanningDate"
  };
};

const getSurgeryDateFilter = selectedFilter => {
  const filteredDates = selectedFilter[0].split("-");
  const firstDate = moment(filteredDates[0], DATE_FORMAT.ISO).format(
    DATE_FORMAT.bigEndian
  );
  const secondDate = moment(filteredDates[1], DATE_FORMAT.ISO).format(
    DATE_FORMAT.bigEndian
  );
  return {
    selectedFilter: `${firstDate}~${secondDate}`,
    filterBy: SURGERY_CASE_ATTR.SURGERY_DATE
  };
};

export const parseInputData = caseList => {
  const parsedData = [];
  caseList.forEach((currentCase, index) => {
    const surgeryDate =
      currentCase?.date && moment(currentCase.date).format(SHORT_DATE_FORMAT);

    const parsedCase = {
      key: index,
      [SURGERY_CASE_ATTR.ID]: checkValue(currentCase?.id),
      [SURGERY_CASE_ATTR.PATIENT_NAME]: checkValue(
        currentCase?.patient.fullName
      ),
      [SURGERY_CASE_ATTR.MODALITY]: checkValue(currentCase?.modality),
      [SURGERY_CASE_ATTR.MRN]: checkValue(currentCase?.medicalRecordNumber),
      [SURGERY_CASE_ATTR.SURGERY_DATE]: checkValue(todayFormat(surgeryDate)),
      [SURGERY_CASE_ATTR.STATE]: checkValue(newCaseState[currentCase?.state]),
      [SURGERY_CASE_ATTR.CLASSIFICATION]: checkValue(
        currentCase?.classification
      ),
      [SURGERY_CASE_ATTR.LAST_MODIFIED]: checkValue(
        getFormatDateFromStringDate(
          currentCase?.planningMetadata.lastModifiedPlanningDate
        )
      ),
      [SURGERY_CASE_ATTR.CASE_NUMBER]: currentCase?.caseNumber,
      [SURGERY_CASE_ATTR.SURGEON_ID]: checkValue(currentCase?.userId),
      [SURGERY_CASE_ATTR.SURGEON_NAME]: checkValue(currentCase?.surgeonName),
      [SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER]: checkValue(
        currentCase?.originalCaseNumber
      )
    };
    parsedData.push(parsedCase);
  });
  return parsedData;
};

export const showCloneStatusMessage = (
  patientName,
  cloneCaseStatus,
  message,
  clearCloneCaseData
) => {
  const statuses = {
    [requestStatus.LOADING]: () =>
      message.loading({ content: `Cloning case...`, duration: 0 }),
    [requestStatus.ERROR]: () =>
      message.error({ content: `Cloning failed ${patientName}`, duration: 3 }),
    [requestStatus.SUCCESS]: () =>
      message.success({
        content: `Case cloned ${patientName}`,
        duration: 3
      })
  };

  (cloneCaseStatus === requestStatus.ERROR ||
    cloneCaseStatus === requestStatus.SUCCESS) &&
    message.destroy();

  cloneCaseStatus && statuses[cloneCaseStatus]();
  clearCloneCaseData();
};

export const getCaseSearchPath = caseId => {
  return `${BO_LOCATION_PATH.SURGERY_CASES}/${caseId}`;
};

export const getCasesOfTicketPath = ticketId => {
  return `${BO_LOCATION_PATH.REQUEST_SUPPORT}/${ticketId}`;
};

export const inputSearch = (filter, placeholder) => (
  <InputSearch
    setSelectedKeys={filter.setSelectedKeys}
    selectedKeys={filter.selectedKeys}
    confirm={filter.confirm}
    clearFilters={filter.clearFilters}
    placeholder={placeholder}
  />
);

export const rangePicker = (setSelectedKeys, confirm) => {
  return <InputDate setSelectedKeys={setSelectedKeys} confirm={confirm} />;
};

export const iconFilteredCalendar = filtered => (
  <Icon type="calendar" className={filtered ? "filtered-date" : ""} />
);

export const iconFiltered = filtered => (
  <Icon type="search" className={filtered ? "filtered-date" : ""} />
);

export const sortDirectionsOptions = [
  SORT_DIRECTIONS.ASCEND,
  SORT_DIRECTIONS.DESCEND
];

export const getListColumn = (
  columnName,
  activeFiltersObject,
  clearFiltersButton = null,
  actionButtons = null
) => {
  let column;

  switch (columnName) {
    case [SURGERY_CASE_ATTR.CASE_NUMBER]:
      column = {
        title: "Case #",
        dataIndex: SURGERY_CASE_ATTR.CASE_NUMBER,
        key: SURGERY_CASE_ATTR.CASE_NUMBER,
        ellipsis: true,
        sorter: (recordA, recordB) =>
          sortFields(recordA.caseNumber, recordB.caseNumber)
      };
      break;
    case [SURGERY_CASE_ATTR.SURGEON_NAME]:
      column = {
        title: "Surgeon",
        dataIndex: SURGERY_CASE_ATTR.SURGEON_NAME,
        key: SURGERY_CASE_ATTR.SURGEON_NAME,
        ellipsis: true,
        sorter: (recordA, recordB) =>
          sortFields(recordA.surgeonName, recordB.surgeonName)
      };
      break;
    case [SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER]:
      column = {
        title: "Original Case #",
        dataIndex: SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER,
        key: SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER,
        ellipsis: true,
        sorter: (recordA, recordB) =>
          sortFields(recordA.originalCaseNumber, recordB.originalCaseNumber)
      };
      break;
    case [SURGERY_CASE_ATTR.MODALITY]:
      column = {
        title: "Modality",
        dataIndex: SURGERY_CASE_ATTR.MODALITY,
        key: SURGERY_CASE_ATTR.MODALITY,
        ellipsis: true,
        filters: modalityFilters,
        filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.MODALITY] || null,
        render: (text, record) => {
          return {
            children: (
              <React.Fragment>
                {record.modality && <QualityTag content={record.modality} />}
              </React.Fragment>
            )
          };
        }
      };
      break;
    case [SURGERY_CASE_ATTR.MRN]:
      column = {
        title: "MRN",
        dataIndex: SURGERY_CASE_ATTR.MRN,
        key: SURGERY_CASE_ATTR.MRN,
        ellipsis: true,
        filterDropdown: filter => inputSearch(filter, "Case"),
        filterIcon: filter => iconFiltered(filter),
        sorter: (recordA, recordB) =>
          sortFields(recordA.medicalRecordNumber, recordB.medicalRecordNumber),
        filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.MRN] || null
      };
      break;

    case [SURGERY_CASE_ATTR.SURGERY_DATE]:
      column = {
        title: "Surgery Date",
        dataIndex: SURGERY_CASE_ATTR.SURGERY_DATE,
        key: SURGERY_CASE_ATTR.SURGERY_DATE,
        ellipsis: true,
        filterDropdown: ({ setSelectedKeys, confirm }) =>
          rangePicker(setSelectedKeys, confirm),
        filterIcon: filter => iconFilteredCalendar(filter),
        sorter: (recordA, recordB) => sortFields(recordA.date, recordB.date),
        filteredValue:
          activeFiltersObject[SURGERY_CASE_ATTR.SURGERY_DATE] || null
      };
      break;

    case [SURGERY_CASE_ATTR.STATE]:
      column = {
        title: "Status",
        dataIndex: SURGERY_CASE_ATTR.STATE,
        key: SURGERY_CASE_ATTR.STATE,
        ellipsis: true,
        filters: statusFilters,
        render: (text, record) => {
          const tagText = record.isDeclined
            ? newCaseState.DECLINED
            : text.toUpperCase();
          return {
            props: {
              className: `status-${tagText.toLowerCase()}`
            },
            children: <Tag>{tagText}</Tag>
          };
        },
        sorter: (recordA, recordB) => sortFields(recordA.state, recordB.state),
        filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.STATE] || null
      };
      break;

    case [SURGERY_CASE_ATTR.CLASSIFICATION]:
      column = {
        title: "Comment",
        dataIndex: SURGERY_CASE_ATTR.CLASSIFICATION,
        key: SURGERY_CASE_ATTR.CLASSIFICATION,
        ellipsis: true,
        filterDropdown: filter => inputSearch(filter, "Comment"),
        filterIcon: filter => iconFiltered(filter),
        sorter: (recordA, recordB) =>
          sortFields(recordA.classification, recordB.classification),
        filteredValue:
          activeFiltersObject[SURGERY_CASE_ATTR.CLASSIFICATION] || null
      };
      break;

    case [SURGERY_CASE_ATTR.LAST_MODIFIED]:
      column = {
        title: "Last modified",
        dataIndex: SURGERY_CASE_ATTR.LAST_MODIFIED,
        key: SURGERY_CASE_ATTR.LAST_MODIFIED,
        ellipsis: true,
        filterDropdown: ({ setSelectedKeys, confirm }) =>
          rangePicker(setSelectedKeys, confirm),
        filterIcon: filter => iconFilteredCalendar(filter),
        sorter: (recordA, recordB) =>
          sortFields(recordA.lastModified, recordB.lastModified),
        filteredValue:
          activeFiltersObject[SURGERY_CASE_ATTR.LAST_MODIFIED] || null
      };
      break;

    case "actions":
      column = {
        title: clearFiltersButton,
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => actionButtons(text, record)
      };
      break;
    default:
      column = null;
  }

  return column;
};
