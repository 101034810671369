import React from "react";
import PropTypes from "prop-types";
import { Input, Button } from "antd";

const InputSearch = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  placeholder
}) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${placeholder}`}
        value={selectedKeys && selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        autoFocus
        ref={input => input && input.focus()}
      />
      <div className="buttons-search">
        <Button type="primary" icon="search" onClick={() => confirm()}>
          Search
        </Button>
        <Button type="default" onClick={() => clearFilters()}>
          Reset
        </Button>
      </div>
    </div>
  );
};

InputSearch.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
  placeholder: PropTypes.string
};

export default InputSearch;
