import { ctScanType } from "gsi-ui-components";
import { isEmpty } from "lodash";

export const filterScans = (firstGroup, secondGroup) => {
  if (isEmpty(firstGroup) || isEmpty(secondGroup)) {
    return null;
  }
  if (
    !firstGroup.ctScanType ||
    !secondGroup.ctScanType ||
    firstGroup.ctScanType === ctScanType.AXIAL
  ) {
    return [firstGroup, secondGroup];
  }
  return [secondGroup, firstGroup];
};

export const getCaseInfo = caseId => async dispatch => {
  const [{ CASE_INFO }, { getCaseUrl }, { sendGetRequest }] = await Promise.all(
    [
      import(`../helpers/types`),
      import(`gsi-ui-components`),
      import(`../helpers/URLHelper`)
    ]
  );

  try {
    dispatch({
      type: CASE_INFO.LOADING
    });

    const response = await sendGetRequest(getCaseUrl(caseId));
    const responseCaseData = response.data.entities[0];
    const caseCTScans = responseCaseData.ctScans.references;
    const ctScansBinaryInfo = await getStoredInfo(
      caseCTScans.map(ctScan => ctScan.uri)
    );
    responseCaseData.ctScansBinaryInfo = ctScansBinaryInfo.map(
      info => info?.data?.entities[0]
    );

    dispatch({
      type: CASE_INFO.SUCCESS,
      payload: responseCaseData
    });
  } catch (error) {
    dispatch({
      type: CASE_INFO.ERROR
    });
  }
};

export const clearCaseInfo = () => async dispatch => {
  const { CASE_INFO } = await import(`../helpers/types`);

  dispatch({
    type: CASE_INFO.CLEAR
  });
};

export const getPlanningInfo =
  (planningUri, caseImagesUri) => async dispatch => {
    const [{ PLANNING_INFO }, { getPlanningUrl }, { sendGetRequest }] =
      await Promise.all([
        import(`../helpers/types`),
        import(`gsi-ui-components`),
        import(`../helpers/URLHelper`)
      ]);

    try {
      const planningData = [];
      const caseImages = [];
      let planningReference;

      dispatch({
        type: PLANNING_INFO.LOADING
      });

      const planner3DImagesImagesBinaryInfo = await getStoredInfo(
        caseImagesUri.map(image => image.uri)
      );
      planner3DImagesImagesBinaryInfo.forEach(image => {
        const imageData = image.data.entities[0];
        if (isEmpty(imageData.surgeryPlanning)) {
          imageData.idx = 4;
          imageData.surgeryPlanning = { id: -1 };
          caseImages.push(imageData);
        }
      });

      for (planningReference of planningUri) {
        const response = await sendGetRequest(
          getPlanningUrl(planningReference.id)
        );
        const responseData = response.data.entities[0];
        const planningImages = responseData.planner3DImages.references;
        const planner3DImagesImagesBinaryInfo = await getStoredInfo(
          planningImages.map(image => image.uri)
        );
        const planIdx = responseData.data.PlanData.idx;
        responseData.planner3DImagesImagesBinaryInfo =
          planner3DImagesImagesBinaryInfo.map(info => {
            return {
              ...info?.data?.entities[0],
              idx: planIdx
            };
          });
        if (planIdx === 0) {
          responseData.planner3DImagesImagesBinaryInfo =
            responseData.planner3DImagesImagesBinaryInfo.concat(caseImages);
        }
        planningData.push(responseData);
      }

      planningData.sort(
        (planningA, planningB) =>
          planningA.data.PlanData.idx - planningB.data.PlanData.idx
      );

      dispatch({
        type: PLANNING_INFO.SUCCESS,
        payload: planningData
      });
    } catch (error) {
      dispatch({
        type: PLANNING_INFO.ERROR
      });
    }
  };

export const clearPlanningInfo = () => async dispatch => {
  const { PLANNING_INFO } = await import(`../helpers/types`);

  dispatch({
    type: PLANNING_INFO.CLEAR
  });
};

const getStoredInfo = async uris => {
  try {
    const [{ sendGetRequest }] = await Promise.all([
      import(`../helpers/URLHelper`)
    ]);

    return await Promise.all(
      uris.map(uri =>
        sendGetRequest(`${process.env.REACT_APP_SURGERY_CORE_URL}${uri}`)
      )
    );
  } catch (error) {
    return null;
  }
};

export const getCaseList =
  (paramUrl = null) =>
  async dispatch => {
    const [{ CASE_LIST }, { getSurgeryCaseUrl }, { sendGetRequest }] =
      await Promise.all([
        import(`../helpers/types`),
        import(`gsi-ui-components`),
        import(`../helpers/URLHelper`)
      ]);
    try {
      dispatch({
        type: CASE_LIST.LOADING
      });
      const response = await sendGetRequest(
        getSurgeryCaseUrl(paramUrl),
        null,
        true
      );

      dispatch({
        type: CASE_LIST.SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: CASE_LIST.ERROR
      });
    }
  };

export const getSupportCasesList =
  (paramUrl = null) =>
  async dispatch => {
    const [
      { REQUEST_SUPPORT_CASES },
      { getSurgeryCaseUrl },
      { sendGetRequest }
    ] = await Promise.all([
      import(`../helpers/types`),
      import(`gsi-ui-components`),
      import(`../helpers/URLHelper`)
    ]);

    const headers = { filter: "withTickets" };

    try {
      dispatch({
        type: REQUEST_SUPPORT_CASES.LOADING
      });
      const response = await sendGetRequest(
        getSurgeryCaseUrl(paramUrl),
        null,
        true,
        headers
      );

      dispatch({
        type: REQUEST_SUPPORT_CASES.SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: REQUEST_SUPPORT_CASES.ERROR
      });
    }
  };

export const cloneCase = caseNumber => async dispatch => {
  const [
    { CLONE_CASE },
    { BODY_PARSER, JSON_CONTENT_TYPE, userService, getCloneCaseUrl },
    { sendPostRequest }
  ] = await Promise.all([
    import(`../helpers/types`),
    import(`gsi-ui-components`),
    import(`../helpers/URLHelper`)
  ]);
  try {
    dispatch({
      type: CLONE_CASE.LOADING
    });

    const body = {
      targetUserId: userService.getUserId(),
      sourceCaseNumber: caseNumber
    };

    await sendPostRequest(
      getCloneCaseUrl(),
      BODY_PARSER.JSON,
      body,
      JSON_CONTENT_TYPE
    );

    dispatch({
      type: CLONE_CASE.SUCCESS
    });
  } catch (error) {
    dispatch({
      type: CLONE_CASE.ERROR
    });
  }
};

export const clearCloneCaseData = () => async dispatch => {
  const { CLONE_CASE } = await import(`../helpers/types.js`);
  dispatch({
    type: CLONE_CASE.CLEAR
  });
};

export const sendSurgeryNote = (caseId, noteValue) => async dispatch => {
  const [
    { CASE_INFO },
    { BODY_PARSER, JSON_CONTENT_TYPE, getCaseUrl },
    { sendPatchRequest }
  ] = await Promise.all([
    import(`../helpers/types`),
    import(`gsi-ui-components`),
    import(`../helpers/URLHelper`)
  ]);
  try {
    dispatch({
      type: CASE_INFO.LOADING
    });

    const body = {
      surgeryNote: noteValue
    };

    const response = await sendPatchRequest(
      getCaseUrl(caseId),
      BODY_PARSER.JSON,
      body,
      JSON_CONTENT_TYPE
    );

    const responseCaseData = response.data.entities[0];

    dispatch({
      type: CASE_INFO.SUCCESS,
      payload: responseCaseData
    });
  } catch (error) {
    dispatch({
      type: CASE_INFO.ERROR
    });
  }
};
