import html2pdf from "html2pdf.js";
import { isEmpty } from "lodash";
import poweredByLogo from "../assets/SI-byGSI-logo.png";
import { filterScans } from "../actions/cases";
import {
  patientMeasurements,
  parseDate,
  parseIsoStringToDate,
  existSurgeryPlanning,
  existSurgeryMeasurements,
  existCustomMeasurements,
  existUserScreenshots,
  parseGlenoidVersion,
  header,
  watermark,
  imageElement,
  getPlanningScreenshot,
  getCustomMeasurements,
  getUserPlanningScreenshots,
  getImplantInfo,
  planningScreenshotsTypes,
  planNameTitle,
  pocketDepthValue,
  defaultImage,
  getReportGenerationTimestamp
} from "./reportGenerationHelper";
import { getPlanningSummary } from "./reportCaseSummaryHelper";

const bold = "font-weight: bold;";

const templateStyles = () => {
  return `<style>
    img {
      z-index: -1;
    }
    .flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .header {
      padding-top: 1rem;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .not-first {
      margin-top: -0.5rem;
    }
    .last-header {
      page-break-before: always;
    }
    .row:after {
      content: "";
      display: table;
      clear: both;
    }
    .column {
      float: left;
    }
    .right-column {
      width: 60%;
    }
    .left-column {
      width: 40%;
    }
    .gap {
      width: 42.2%;
    }
    .title {
      ${bold}
      padding-top: 2rem;
      padding-bottom: 2rem;
      text-align: center;
      font-size: 1.17rem;
    }
    .container {
      position: relative;
    }
    .body-fonts {
      font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI",
        "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
      background: transparent !important;
    }
    .column-content {
      padding-left: 4rem;
    }
    .column-content-title {
      padding-bottom: 1rem;
      ${bold}
      font-size: 1rem;
      color: black;
    }
    .content-key {
      color: gray;
      font-size: 0.92rem;
      font-weight: 100;
    }
    .content-value {
      padding-bottom: 1rem;
      font-size: 0.92rem;
      color: black;
      font-weight: 100;
    }
    .header-img {
      padding-left: 4rem;
    }
    .case-date-main {
      padding-right: 4rem;
      padding-top: 1rem;
      font-size: 0.8rem;
    }
    .case-date {
      color: black;
    }
    .patient-scans {
      padding-top: 2rem;
      page-break-after: always;
    }
    .coronal-view {
      padding-top: 2.2rem;
      right: 3rem;
    }
    .inscription {
      font-size: 0.92rem;
    }
    .inscription-surgery {
      padding-bottom: 0.3rem
    }
    .patient-measurements {
      padding-top: 2rem;
    }
    .scapular-glenoid {
      padding-top: 2.2rem;
      right: 3rem;
    }
    .watermark {
      color: lightgray;
      font-size: 3rem;
      ${bold}
      opacity: 0.25;
      margin: auto;
      left: 10rem;
    }
    .first-watermark {
      z-index: -1;
      position: absolute;
      top: 17rem;
    }
    .second-watermark {
      z-index: 0;
      position: absolute;
      top: 20rem;
      left: 9.5rem;
    }
    .third-watermark {
      z-index: 0;
      position: absolute;
      top: 60rem;
      left: 9.5rem;
    }
    .fourth-watermark {
      z-index: 9999;
      position: relative;
      top: 19rem;
    }
    .notes {
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      max-width: 50rem;
    }
    .patient-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 20rem;
    }
    .surgeon-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 20rem;
    }
    .three-row:after {
      content: "";
      display: table;
      clear: both;
    }
    .three-column {
      float: left;
    }
    .plan-name-title {
      padding-left: 4rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .plan-name-content {
      margin-bottom: 0;
      background-color: #0050B3;
      color: white;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0.25rem 0.5rem;
      margin: 0;
    }
    .plan-name-line {
      margin: 0 4rem 0 4rem;
      border-bottom: 1px solid #e6e6e6;
    }
    .surgery-options-break:last-child {
      page-break-after: avoid;
    }
  </style>`;
};

const surgeryCaseSection = reportData => {
  return ` ${header(true, false)}
  ${watermark("first")}
  <div class="title">
    Case report #${reportData.caseNumber}
  </div>
  <div class="row">
    <div class="column left-column">
      <div class="column-content">
        <div class="column-content-title">
          Case information
        </div>
        <div class="content-key">Surgery date</div>
        <div class="content-value">${
          reportData.isDeclined
            ? "Surgery declined"
            : reportData.surgeryDate || "-"
        }</div>
        <div class="content-key">Plan last modified</div>
        <div class="content-value">${parseIsoStringToDate(
          reportData.lastModifiedPlanningDate
        )}</div>
        <div class="content-key">Notes</div>
        <div class="content-value">
          <label class="notes">${reportData.notes || "-"}</textarea>
        </div>
      </div>
    </div>
    <div class="column right-column">
      <div class="column-content">
        <div class="column-content-title">
          Patient profile
        </div>
        <div class="content-key">Name</div>
        <div class="content-value patient-name">${
          reportData.patientName || "-"
        }</div>
        <div class="content-key">MRN</div>
        <div class="content-value">${reportData.mrn || "-"}</div>
        <div class="content-key">Gender</div>
        <div class="content-value">${
          reportData.gender.charAt(0) + reportData.gender.slice(1).toLowerCase()
        }</div>
        <div class="content-key">Date of birth</div>
        <div class="content-value">${
          reportData.dateOfBirth ? parseDate(reportData.dateOfBirth) : "-"
        }</div>
        <div class="content-key">Age</div>
        <div class="content-value">${reportData.age || "-"}</div>
      </div>
    </div>
  </div>
  <div class="row patient-scans">
  ${imageElement(
    "Axial view",
    null,
    "Patient's scans",
    "left",
    reportData.ctScans
      ? "data:image/jpeg;base64," + reportData.ctScans.axialPreview
      : defaultImage
  )}
  ${imageElement(
    "Coronal view",
    "coronal-view",
    null,
    "right",
    reportData.ctScans
      ? "data:image/jpeg;base64," + reportData.ctScans.coronalPreview
      : defaultImage
  )}
  </div>`;
};

const patientMeasurementsSection = (reportData, caseSummary) => {
  const firstCaseSummary = caseSummary[0];
  const generatedMeasurements =
    reportData?.surgeryMeasurements?.state === "ACCEPTED"
      ? "Automated"
      : "Surgeon modified";
  const inclinationDirection =
    reportData?.surgeryMeasurements?.patientProfileData?.GlenoidInclination >= 0
      ? "Glenoid inc. superior"
      : "Glenoid inc. inferior";
  const glenoidInclinationValue = firstCaseSummary?.glenoidInclination
    ? Math.abs(firstCaseSummary.glenoidInclination) + "°"
    : "-";
  const glenoidVersionValue = firstCaseSummary?.glenoidVersion
    ? Math.abs(firstCaseSummary.glenoidVersion) + "°"
    : "-";

  return `${patientMeasurements(
    null,
    null,
    {
      watermarkOrder: "second",
      notFirst: false,
      lastHeader: false,
      showPlanName: false
    },
    {
      altContentValue: "Scapula sagittal plane",
      divClass: null,
      columnTitle: "Patient's measurements",
      firstImage: getPlanningScreenshot(
        reportData.planningScreenshots,
        planningScreenshotsTypes.SCAPULA_SAGITTAL_PLANE_WITHOUT_IMPLANT,
        0
      )
    },
    {
      secondAltContentValue: "Scapular and glenoid planes",
      secondDivClass: "scapular-glenoid",
      secondColumnTitle: null,
      secondImage: getPlanningScreenshot(
        reportData.planningScreenshots,
        planningScreenshotsTypes.SCAPULA_AND_GLENOID_PLANES,
        0
      )
    }
  )}
  <div class="row patient-measurements">
    ${imageElement(
      "Axial view subluxation",
      null,
      null,
      "left",
      getPlanningScreenshot(
        reportData.planningScreenshots,
        planningScreenshotsTypes.AXIAL_SUBLUXATION_VIEW,
        0
      )
    )}
    <div class="column right-column">
      <div class="column-content">
      <div class="content-key inscription">Measurements</div>
            <div class="content-value inscription">${
              reportData.surgeryMeasurements &&
              reportData.surgeryMeasurements.state &&
              reportData.surgeryMeasurements.state !== "UNCHECKED"
                ? generatedMeasurements
                : "-"
            }</div>
        <div class="content-key inscription">Operation side</div>
        <div class="content-value inscription">${
          reportData.operationSide.charAt(0) +
          reportData.operationSide.slice(1).toLowerCase()
        }</div>
        <div class="content-key inscription">${
          existSurgeryMeasurements(reportData)
            ? inclinationDirection
            : "Glenoid inclination"
        }</div>
        <div class="content-value inscription">${
          existSurgeryMeasurements(reportData) ? glenoidInclinationValue : "-"
        }</div>
        <div class="content-key inscription">${
          existSurgeryMeasurements(reportData)
            ? parseGlenoidVersion(
                reportData.surgeryMeasurements.patientProfileData
                  .GlenoidVersion,
                reportData.operationSide
              )
            : "Glenoid version"
        }</div>
        <div class="content-value inscription">${
          existSurgeryMeasurements(reportData) ? glenoidVersionValue : "-"
        }</div>
        <div class="content-key inscription">Subluxation</div>
        <div class="content-value inscription">${
          existSurgeryMeasurements(reportData)
            ? firstCaseSummary.humerusSubluxation || "-"
            : "-"
        }</div>
        <div class="content-key inscription">Comment</div>
        <div class="content-value inscription">${
          reportData.comment || "-"
        }</div>
      </div>
    </div>
  </div>`;
};

const screenshotsSection = (reportData, planIdx, showPlanName) => {
  return `${
    existUserScreenshots(reportData)
      ? getUserPlanningScreenshots(reportData, planIdx, showPlanName)
      : ""
  }`;
};

const medLatElement = (reportDataObject, caseSummary, planIdx) => {
  const planningSummary = caseSummary[planIdx];

  return `<div class="row">
  <div class="column">
    <div class="content-key inscription">${
      existSurgeryPlanning(reportDataObject, planIdx)
        ? planningSummary.medializationLateralizationType
        : "Medialization"
    }</div>
    <div class="content-value inscription inscription-surgery">${
      existSurgeryPlanning(reportDataObject, planIdx)
        ? planningSummary.medializationLateralization + "mm"
        : "-"
    }</div>
  </div>
</div>`;
};

const getSurgeryType = (reportDataObject, planIdx) => {
  const procedureType =
    reportDataObject?.surgeryPlannings[planIdx]?.data?.Procedure === 0
      ? "Face"
      : "Gleno";
  return existSurgeryPlanning(reportDataObject, planIdx) ? procedureType : "-";
};

const pocketDepthFields = (pocketValue, fieldName, isColumnGap) => {
  return `<div class="${isColumnGap}">
  <div class="content-key inscription">${fieldName}</div>
  <div class="content-value inscription inscription-surgery">
    ${pocketDepthValue(pocketValue)}
  </div>
</div>`;
};

const faceVersionInformation = (
  reportData,
  planIdx,
  planningSummary,
  surgeryType
) => {
  return `<div class="content-key inscription">${
    existSurgeryPlanning(reportData, planIdx)
      ? "Pocket " + planningSummary.versionType
      : "Pocket Version"
  }</div>
  <div class="content-value inscription inscription-surgery">${
    existSurgeryPlanning(reportData, planIdx)
      ? Math.abs(planningSummary.version) + "°"
      : "-"
  }</div>
</div>
<div class="column">
  <div class="content-key inscription">${
    existSurgeryPlanning(reportData, planIdx)
      ? surgeryType + " " + planningSummary.secondaryVersionType
      : surgeryType + " Version"
  }</div>
  <div class="content-value inscription inscription-surgery">${
    existSurgeryPlanning(reportData, planIdx)
      ? Math.abs(planningSummary.faceVersion) + "°"
      : "-"
  }</div>`;
};

const pocketInformation = (
  reportData,
  planIdx,
  surgeryType,
  planningSummary
) => {
  return `<div class="column gap">
            <div class="content-key inscription">${
              existSurgeryPlanning(reportData, planIdx)
                ? "Pocket " + planningSummary.inclinationType
                : "Pocket Inclination"
            }</div>
            <div class="content-value inscription inscription-surgery">${
              existSurgeryPlanning(reportData, planIdx)
                ? Math.abs(planningSummary.pocketInclination) + "°"
                : "-"
            }</div>
          </div>
          <div class="column">
            <div class="content-key inscription">${
              existSurgeryPlanning(reportData, planIdx)
                ? surgeryType + " " + planningSummary.secondaryInclinationType
                : surgeryType + " Inclination"
            }</div>
            <div class="content-value inscription inscription-surgery">${
              existSurgeryPlanning(reportData, planIdx)
                ? Math.abs(planningSummary.faceInclination) + "°"
                : "-"
            }</div>
          </div>`;
};

const surgeryOptionsSection = (
  reportData,
  caseSummary,
  planIdx,
  pocketDepth
) => {
  const surgeryType = getSurgeryType(reportData, planIdx);
  const planningData = reportData.surgeryPlannings[planIdx].data;
  const planningSummary = caseSummary[planIdx];
  const procedure = planningData.Procedure === 0 ? "Anatomic" : "Reverse";
  const glenoidSizeValue = planningSummary.glenoidSize
    ? planningSummary.glenoidSize + "mm"
    : "-";
  const glenoidSize =
    "<div class='content-key inscription'>Size</div><div class='content-value inscription inscription-surgery'>" +
    (existSurgeryPlanning(reportData, planIdx) ? glenoidSizeValue : "-") +
    "</div>";

  return `${patientMeasurements(
    planningData.PlanData.name,
    planningData.PlanData.isFavorite,
    {
      watermarkOrder: "third",
      notFirst: false,
      lastHeader: true,
      showPlanName: true
    },
    {
      altContentValue: "Scapula sagittal plane with implant",
      divClass: null,
      columnTitle: "Surgery options",
      firstImage: getPlanningScreenshot(
        reportData.planningScreenshots,
        planningScreenshotsTypes.SCAPULA_SAGITTAL_PLANE_WITH_IMPLANT,
        planIdx
      )
    },
    {
      secondAltContentValue: "Axial view with implant",
      secondDivClass: "scapular-glenoid",
      secondColumnTitle: null,
      secondImage: getPlanningScreenshot(
        reportData.planningScreenshots,
        planningScreenshotsTypes.AXIAL_VIEW_WITH_IMPLANT,
        planIdx
      )
    }
  )}
  <div class="row patient-measurements">
    ${imageElement(
      "Coronal view with implant",
      null,
      null,
      "left",
      getPlanningScreenshot(
        reportData.planningScreenshots,
        planningScreenshotsTypes.CORONAL_VIEW_PLANE_WITH_IMPLANT,
        planIdx
      )
    )}
    <div class="column right-column">
      <div class="column-content">
        <div class="column-content-title">
          Procedure
        </div>
        <div class="row">
          <div class="column">
            <div class="content-key inscription">Procedure</div>
            <div class="content-value inscription">${
              existSurgeryPlanning(reportData, planIdx) ? procedure : "-"
            }</div>
          </div>
          ${getImplantInfo(reportData, caseSummary, planIdx)}
        </div>
        <div class="column-content-title">
          Surgery properties
        </div>
        <div class="row">
           ${pocketInformation(
             reportData,
             planIdx,
             surgeryType,
             planningSummary
           )} 
        </div>
        <div class="row">
          <div class="column gap">
            ${faceVersionInformation(
              reportData,
              planIdx,
              planningSummary,
              surgeryType
            )}
          </div>
        </div>
        <div class="row">
        ${pocketDepthFields(
          pocketDepth.anteriorDepth,
          "Ant depth",
          "column gap"
        )}
        ${pocketDepthFields(pocketDepth.posteriorDepth, "Post depth", "column")}
        </div>
        <div class="row">
        ${pocketDepthFields(
          pocketDepth.superiorDepth,
          "Sup depth",
          "column gap"
        )}
        ${pocketDepthFields(pocketDepth.inferiorDepth, "Inf depth", "column")}
        </div>
        <div class="row">
          <div class="column gap">
          ${
            existSurgeryPlanning(reportData, planIdx) &&
            planningData.Procedure === 0
              ? glenoidSize
              : medLatElement(reportData, caseSummary, planIdx)
          }
          </div>
          <div class="column"
          }">
            <div class="content-key inscription">Rotation</div>
            <div class="content-value inscription inscription-surgery">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.rotation + "°"
                : "-"
            }</div>
          </div>
        </div>
        ${
          existSurgeryPlanning(reportData, planIdx) &&
          planningData.Procedure === 0
            ? medLatElement(reportData, caseSummary, planIdx)
            : "<div></div>"
        }
        <div class="row">
          <div class="column gap">
            <div class="content-key inscription">Rim &#62;= 2mm</div>
            <div class="content-value inscription inscription-surgery">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.rimMajor
                : "-"
            }</div>
          </div>
          <div class="column">
            <div class="content-key inscription">Rim &#60; 0.1 to 2mm</div>
            <div class="content-value inscription inscription-surgery">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.rimMinor
                : "-"
            }</div>
          </div>
        </div>
        <div class="row">
          <div class="column gap">
            <div class="content-key inscription">No Rim inset</div>
            <div class="content-value inscription inscription-surgery">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.noRimInset
                : "-"
            }</div>
          </div>
          <div class="column">
            <div class="content-key inscription">No seating</div>
            <div class="content-value inscription inscription-surgery">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.noSeating
                : "-"
            }</div>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <div class="content-key inscription">Backside support</div>
            <div class="content-value inscription inscription-surgery">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.backsideSupport
                : "-"
            }</div>
          </div>
        </div>
      </div>
    </div>
  </div>`;
};

const reverseOptionsSection = (reportData, caseSummary, planIdx) => {
  const planningData = reportData.surgeryPlannings[planIdx].data.PlanData;
  const planningSummary = caseSummary[planIdx];

  return ` ${header(false, true)}
  ${watermark("fourth")}
  <div class="row" style="margin-top: -3.5rem">
    ${planNameTitle(planningData.name, planningData.isFavorite)}
    <div class="column">
      <div class="column-content">
        <div class="column-content-title">
          Surgery options
        </div>
        <div class="column-content-title">
          Baseplate properties
        </div>
        <div class="three-row">
          <div class="three-column">
            <div class="content-key">Diameter</div>
            <div class="content-value">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.basePlateDiameter + "mm"
                : "-"
            }</div>
          </div>
          <div class="three-column" style="padding-left: 5.5rem;">
            <div class="content-key">Stem</div>
            <div class="content-value">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.basePlateStem + "mm"
                : "-"
            }</div>
          </div>
          <div class="three-column" style="padding-left: 6.5rem;">
            <div class="content-key">Angle</div>
            <div class="content-value">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.basePlateAngle + "°"
                : "-"
            }</div>
          </div>
        </div>

        <div class="column-content-title">
          6.0mm central screw
        </div>
        <div class="content-key">Length</div>
        <div class="content-value">${
          existSurgeryPlanning(reportData, planIdx)
            ? planningSummary.centralScrew + "mm"
            : "-"
        }</div>

        <div class="column-content-title">
          Glenosphere
        </div>
        <div class="three-row">
          <div class="three-column">
            <div class="content-key">Type</div>
            <div class="content-value">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.glenosphereType
                : "-"
            }</div>
          </div>
          <div class="three-column" style="padding-left: 5.8rem;">
            <div class="content-key">Diameter</div>
            <div class="content-value">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.glenosphereDiameter + "mm"
                : "-"
            }</div>
          </div>
          <div class="three-column" style="padding-left: 5rem;">
            <div class="content-key">${planningSummary.glenosphereLabel}</div>
            <div class="content-value">${
              existSurgeryPlanning(reportData, planIdx)
                ? planningSummary.glenosphereValue
                : "-"
            }</div>
          </div>
        </div>
      </div>
    </div>
  </div>`;
};

const customMeasurementsSection = (reportData, planIdx) => {
  const planningData = reportData.surgeryPlannings[planIdx].data;

  return `${
    existSurgeryPlanning(reportData, planIdx) &&
    existCustomMeasurements(reportData, planIdx)
      ? header(false, true) +
        watermark("fourth") +
        "<div style='margin-top: -3.5rem'>" +
        planNameTitle(
          planningData.PlanData.name,
          planningData.PlanData.isFavorite
        ) +
        "<div class='column-content column-content-title'>Measurements</div>" +
        "<img class='header-img' width='650px' height='600px' src='" +
        getPlanningScreenshot(
          reportData.planningScreenshots,
          planningScreenshotsTypes.SCAPULA_SAGITTAL_PLANE_WITH_MEASUREMENTS,
          planIdx
        ) +
        "'/>" +
        "<div class='column-content'>" +
        getCustomMeasurements(planningData.CustomMeasurements) +
        "</div></div>"
      : ""
  }`;
};

export const getPdfContent = (data, caseSummary) => {
  const reportData = { ...data };

  return `<!DOCTYPE html>
  <html style="background: transparent !important;">
    ${templateStyles()}
    <body class="body-fonts">
      ${surgeryCaseSection(reportData)}
  
      ${patientMeasurementsSection(reportData, caseSummary)}

      ${screenshotsSection(reportData, 4, false)}

      ${
        !isEmpty(reportData.surgeryPlannings) &&
        (() => {
          let res = "";

          for (const planning of reportData.surgeryPlannings) {
            const planIdx = planning.data.PlanData.idx;

            const implantData = planning.data.ImplantData;

            const pocketDepth = {
              anteriorDepth: implantData.AnteriorDepth,
              inferiorDepth: implantData.InferiorDepth,
              superiorDepth: implantData.SuperiorDepth,
              posteriorDepth: implantData.PosteriorDepth
            };

            res += surgeryOptionsSection(
              reportData,
              caseSummary,
              planIdx,
              pocketDepth
            );

            if (
              existSurgeryPlanning(reportData, planIdx) &&
              reportData.surgeryPlannings[planIdx].data.Procedure === 1
            ) {
              res += reverseOptionsSection(reportData, caseSummary, planIdx);
            } else {
              res += "";
            }
            res += customMeasurementsSection(reportData, planIdx);
            res += screenshotsSection(reportData, planIdx, true);
          }
          return res;
        })()
      }
    </body>
  </html>  
  `;
};

export const generateReport = (
  caseInfo,
  planningInfo,
  patientValues,
  lastModifiedPlanningDates
) => {
  const caseInfoData = caseInfo.data;
  const filteredScans = filterScans(
    caseInfoData.ctScansBinaryInfo[0],
    caseInfoData.ctScansBinaryInfo[1]
  );
  const reportScans = filteredScans
    ? {
        axialPreview: filteredScans[0].binaryCTScan,
        coronalPreview: filteredScans[1].binaryCTScan
      }
    : null;
  const planningScreenshots = [];
  const planningUserScreenshots = [];
  planningInfo.data.forEach(planning => {
    planning.planner3DImagesImagesBinaryInfo.forEach(planningImage => {
      if (planningImage.imageType === "OTHER") {
        planningUserScreenshots.push({
          binaryPlanner3D: planningImage.binaryPlanner3D,
          comment: planningImage.comment,
          id: planningImage.id,
          imageType: planningImage.imageType,
          planId: planningImage.surgeryPlanning.id,
          planIdx: planningImage.idx,
          screenshotDate: planningImage.screenshotDate
        });
      } else {
        planningScreenshots.push({
          binaryPlanner3D: planningImage.binaryPlanner3D,
          imageType: planningImage.imageType,
          idx: planningImage.idx
        });
      }
    });
  });

  const pdfContent = getPdfContent(
    {
      age: patientValues.Age,
      caseNumber: caseInfoData.caseNumber,
      comment: caseInfoData.classification,
      ctScans: reportScans,
      dateOfBirth: patientValues["Date of birth"],
      gender: patientValues.Gender,
      isDeclined: caseInfoData.isDeclined,
      lastModifiedPlanningDate: lastModifiedPlanningDates.sort(
        (a, b) => new Date(b) - new Date(a)
      )[0],
      mrn: patientValues.MRN,
      notes: caseInfoData.surgeryNote,
      operationSide: caseInfoData.operationSide,
      patientName: patientValues.Name,
      planningScreenshots,
      planningUserScreenshots,
      surgeryDate: caseInfoData.date,
      surgeryMeasurements: caseInfoData.surgeryMeasurements,
      surgeryPlannings: planningInfo.data
    },
    planningInfo.data.map(planning =>
      getPlanningSummary(
        planning,
        caseInfoData.surgeryMeasurements,
        caseInfoData.operationSide
      )
    )
  );

  openReport(pdfContent, patientValues);
};

const openReport = (pdfContent, patientValues) => {
  html2pdf()
    .set({
      margin: [5, 5, 2, 5],
      html2canvas: { scale: 3 }
    })
    .from(pdfContent)
    .toPdf()
    .get("pdf")
    .then(pdf => {
      pdf.setProperties({
        title: `${patientValues.Name} - ${getReportGenerationTimestamp()}`
      });
      addReportFooter(pdf);
      window.open(pdf.output("bloburl"));
    });
};

const addReportFooter = pdf => {
  const pdfInternal = pdf.internal;
  const pageSize = pdfInternal.pageSize;
  const pdfWidth = pageSize.getWidth();
  const pdfHeight = pageSize.getHeight();
  const totalPages = pdfInternal.getNumberOfPages();
  let currentPage = 1;

  for (currentPage; currentPage <= totalPages; currentPage++) {
    pdf.setPage(currentPage);
    pdf.setFontSize(10);
    pdf.setTextColor("#808080");
    pdf.text(pdfWidth - 30, pdfHeight - 10, `${currentPage}/${totalPages}`);

    pdf.setFontSize(9);
    pdf.setTextColor("#000000");
    pdf.text(pdfWidth - 162, pdfHeight - 15, "GSI Preview Shoulder");
    pdf.text(
      pdfWidth - 162,
      pdfHeight - 10,
      `©2019-${new Date().getFullYear()} Genesis Software Innovations`
    );

    pdf.addImage(poweredByLogo, "png", pdfWidth - 188, pdfHeight - 19, 25, 9);
  }
};
