import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { BaseButton, BO_LOCATION_PATH } from "gsi-ui-components";
import { setCaseTaskInfo } from "../../../actions/tickets";
import { updateTaskState } from "../../../actions/tasks";
import {
  TASK_STATUS,
  TASK_STATUS_EXECUTE,
  TASK_TYPE_PARAMS
} from "../../../helpers/ticketsHelper";
import "./DetailInfo.less";

const taskStates = ["new", "in-progress", "completed"];

const DetailInfo = ({
  index = null,
  icon,
  title,
  content,
  caseTaskInfo = null,
  setCaseTaskInfo,
  updateTaskState
}) => {
  const updateState = () => {
    const currentIndex = taskState[1];
    if (currentIndex < 2) {
      const newState = taskStates[currentIndex + 1];
      const backendState = Object.keys(TASK_STATUS).find(
        key => TASK_STATUS[key] === newState
      );
      setTaskState([newState, currentIndex + 1]);
      caseTaskInfo.state = backendState;
      updateTaskState(caseTaskInfo.id, backendState.toUpperCase());
    }
  };

  const [taskState, setTaskState] = useState([null, 0]);

  useEffect(() => {
    if (caseTaskInfo) {
      const { state } = caseTaskInfo;
      let formattedState = state !== TASK_STATUS_EXECUTE ? state : null;

      setTaskState([
        TASK_STATUS[formattedState],
        taskStates.indexOf(TASK_STATUS[formattedState])
      ]);
    }
  }, [caseTaskInfo]);

  return (
    <div className={`info-container ${index ? "three-col" : "two-col"}`}>
      {icon && (
        <BaseButton
          config={{
            className: `info-icon ${taskState[0]}`,
            shape: "circle",
            icon: icon
          }}
          onClick={() => updateState()}
        />
      )}
      <div className="info-title">{title}</div>
      <div className="info-content">
        {caseTaskInfo ? (
          <span>
            <Link
              to={`${BO_LOCATION_PATH.CASE_TASK}/${
                TASK_TYPE_PARAMS[caseTaskInfo.task]
              }/${caseTaskInfo.ticketId}`}
              onClick={() => setCaseTaskInfo(caseTaskInfo)}
            >
              {content}
            </Link>
          </span>
        ) : (
          content
        )}
      </div>
    </div>
  );
};

DetailInfo.propTypes = {
  index: PropTypes.number,
  icon: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  url: PropTypes.string,
  setCaseTaskInfo: PropTypes.func,
  updateTaskState: PropTypes.func,
  caseTaskInfo: PropTypes.object
};

export default React.memo(
  connect(null, { setCaseTaskInfo, updateTaskState })(DetailInfo)
);
