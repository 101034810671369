import {
  bankersRound,
  getBasePlateAngle,
  getBasePlateDiameter,
  getBasePlateStem,
  getGlenoidSize,
  getGlenosphereDiameter,
  getGlenosphereLabel,
  getGlenosphereValue,
  getImplantType,
  getMedLat,
  getNoRimInset,
  getNoSeating,
  parseCentralScrew,
  parseGlenosphereType,
  parseHumerusSubluxation,
  parseInclination,
  parseMedLat,
  parseRim,
  parseRotation,
  parseSeating,
  parseToDecimals,
  parseVersion
} from "gsi-ui-components";

export const getPlanningSummary = (
  planning,
  surgeryMeasurements,
  operationSide
) => {
  const planningData = planning.data;
  const implantData = planningData.ImplantData;
  let planningSummary = {
    backsideSupport: parseSeating(implantData.Seating),
    faceInclination: Math.abs(bankersRound(implantData.SecondaryInclination)),
    faceVersion: Math.abs(bankersRound(implantData.SecondaryVersion)),
    glenoidInclination: Math.abs(
      parseToDecimals(
        surgeryMeasurements.patientProfileData.GlenoidInclination,
        2
      )
    ),
    glenoidVersion: Math.abs(
      parseToDecimals(surgeryMeasurements.patientProfileData.GlenoidVersion, 2)
    ),
    humerusSubluxation: parseHumerusSubluxation(
      surgeryMeasurements.patientProfileData.HumerusSubluxation
    ),
    inclinationType: parseInclination(
      planningData.Procedure === 1
        ? implantData.SecondaryInclination
        : implantData.Inclination
    ),
    medializationLateralization: parseMedLat(implantData.Lateralization),
    medializationLateralizationType: getMedLat(implantData.Lateralization),
    noRimInset: getNoRimInset(
      implantData.RimFullSeating,
      implantData.RimPartialSeating
    ),
    noSeating: getNoSeating(implantData.Seating),
    rimMajor: parseRim(implantData.RimFullSeating),
    rimMinor: parseRim(implantData.RimPartialSeating),
    rotation: parseRotation(implantData.Rotation),
    secondaryInclinationType: parseInclination(
      implantData.SecondaryInclination
    ),
    secondaryVersionType: parseVersion(
      bankersRound(implantData.SecondaryVersion),
      operationSide
    ),
    version: Math.abs(
      bankersRound(
        planningData.Procedure === 1
          ? implantData.SecondaryVersion
          : implantData.Version
      )
    ),
    versionType: parseVersion(
      bankersRound(
        planningData.Procedure === 1
          ? implantData.SecondaryVersion
          : implantData.Version
      ),
      operationSide
    ),
    pocketInclination: Math.abs(
      bankersRound(
        planningData.Procedure === 1
          ? implantData.SecondaryInclination
          : implantData.Inclination
      )
    )
  };

  if (planningData.Procedure === 0) {
    planningSummary.glenoidSize = getGlenoidSize(
      implantData.AnatomicOptsData.Depth,
      implantData.AnatomicOptsData.Diameter,
      implantData.AnatomicOptsData.ImplantType
    );
    planningSummary.implantType = getImplantType(planningData);
  } else {
    planningSummary.basePlateAngle = getBasePlateAngle(
      implantData.ReverseOptsData.BaseplateAngle
    );
    planningSummary.basePlateDiameter = getBasePlateDiameter(
      implantData.ReverseOptsData.BaseplateDiameter
    );
    planningSummary.basePlateStem = getBasePlateStem(
      implantData.ReverseOptsData.BaseplateStem
    );
    planningSummary.centralScrew = parseCentralScrew(
      implantData.ReverseOptsData.CentralScrew
    );
    planningSummary.glenosphereDiameter = getGlenosphereDiameter(
      implantData.ReverseOptsData.GlenosphereDiameter
    );
    planningSummary.glenosphereLabel = getGlenosphereLabel(
      implantData.ReverseOptsData.GlenosphereType
    );
    planningSummary.glenosphereType = parseGlenosphereType(
      implantData.ReverseOptsData.GlenosphereType
    );
    planningSummary.glenosphereValue = getGlenosphereValue(
      implantData.ReverseOptsData.GlenosphereOffset,
      parseRotation(implantData.ReverseOptsData.GlenosphereRotation),
      implantData.ReverseOptsData.GlenosphereType
    );
  }

  return planningSummary;
};
